import { useSelector } from "react-redux";

import { selectCurrentDeviceId } from "../../data/uiSlice";
import DeviceSelect from "./DeviceSelect";
import DeviceDetailsDialog from "./DeviceDetailsDialog";

import {
  Stack,
} from "@mui/material";

const DeviceHeader = ({sx}) => {
  const deviceId = useSelector(selectCurrentDeviceId);

  return (
    <Stack
     direction="row"
     spacing={2}
     justifyContent="center"
     sx={{
       mt: 2,
       ...sx
      }}
    >
     <DeviceSelect />
     <DeviceDetailsDialog
      deviceId={deviceId}
      hideDataButton
      hideSettingsButton
    />
    </Stack>
  );
}
export default DeviceHeader;