import { Input } from "@mui/material"

export default function TextInput({value, multiline, maxRows=8, placeholder, textOnly, disabled, onChange, inputProps, sx}) {
  if(textOnly){
    return value
  }

  return (
    <Input 
      sx={{width:"100%", ...sx}}
      multiline={multiline}
      maxRows={maxRows}
      id="TextField" 
      type="text" 
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      inputProps={inputProps}
    />
  )
}