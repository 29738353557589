import MultiGuardIcon from "./MultirGuardIcon";
import MultiGuardLetterIcon from "./MultiGuardLetterIcon";
import LeakGuardIcon from "./LeakGuardIcon";
import LeakGuardLetterIcon from "./LeakGuardLetterIcon";
import PressureGuardIcon from "./PressureGuardIcon";
import PressureGuardLetterIcon from "./PressureGuardLetterIcon";
import SystemSecurityUpdateWarningIcon from '@mui/icons-material/SystemSecurityUpdateWarning';

import {Tooltip} from "@mui/material";

const IconComponent = {
  pure: {
    "MultiGuard THVCLPS": MultiGuardIcon,
    "MG Olosuhdevahti": MultiGuardIcon,
    "LeakGuard": LeakGuardIcon,
    "PressureGuard": PressureGuardIcon,
    // default: SystemSecurityUpdateWarningIcon
  },
  letter: {
    "MultiGuard THVCLPS": MultiGuardLetterIcon,
    "MG Olosuhdevahti": MultiGuardLetterIcon,
    "LeakGuard": LeakGuardLetterIcon,
    "PressureGuard": PressureGuardLetterIcon,
    // default: SystemSecurityUpdateWarningIcon
  },
}

const TooltipWrapper = ({children, type, disabled}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={type}>{children}</Tooltip>;
}


// An element that renders device type names into corresponding icons.
// type: type name str, 
// variant: str (null (def) | 'letter')
// fontSize: str ('small' (def) | 'large' |...) // icon size
// color: str
// tooltipDisabled: bool
// sx: styles of the icon
const DeviceTypeIcon = ({type, variant, fontSize='small', color, tooltipDisabled, sx}) => {
  if(type == null) {
    return false;
  }

  const variantKey = variant === "letter" ? "letter" : "pure"
  const Icon = IconComponent[variantKey][type] || SystemSecurityUpdateWarningIcon;

  return (
    <TooltipWrapper type={type} disabled={tooltipDisabled}>
      <Icon sx={{...sx, color}} fontSize={fontSize} />
    </TooltipWrapper>
  );
}
export default DeviceTypeIcon;