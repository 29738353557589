import { useState } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import useParamSelector from "../../data/useParamSelector.js";

import { useUiDescQuery, useKeyValueQuery } from "../../queries/deviceQuery.js";

import { unit } from '../../utility/influxChartConfig.mjs';
import { prettifyLeakSensorADValue } from "../../utility/deviceConfig.js";
import { selectUnitSystem } from "../../data/uiSlice.js";
import { selectDeviceById } from "../../data/devicesSlice.js";
import { 
  selectLocationByDeviceId, 
  selectLocationFullPathById 
} from "../../data/locationsSlice.js";
import { RawDialog } from "../InfoDialog.js";
import { PrettyTime } from "../Time/PrettyTime.js";
import { AlarmIcon } from "../Icons/AlarmIcon.js";
import { DataList } from "../DataList/DataList.js";

import { 
  CircularProgress,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Tooltip, 
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import alarmsSlice from "../../data/alarmsSlice.js";

const PureAlarmDialog = ({t, alarm, openButtonType="alarmIcon", tooltipTitle, tooltipDisabled}) => {
  const alarmType = alarm?.type;
  const unitSystem = useSelector(selectUnitSystem);
  const [open, setOpen] = useState(false);
  const device = useSelector(selectDeviceById(alarm?.deviceId));
  const location = useSelector(selectLocationByDeviceId(alarm?.deviceId));
  const path = useParamSelector(selectLocationFullPathById, location?.id) ?? "";

  const { data:uiDesc, isLoading:uiDescIsLoading } = useUiDescQuery({
    deviceId: device.id,
    enabled: open && !device.dataIsLoaded
  });

  const { data:keyValues, isLoading:keyValuesIsLoading } = useKeyValueQuery({
    device, 
    uiDesc, 
    enabled: open && !!uiDesc
  });

  const addLatestValueRow = (dataArray) => {
    if(alarmType == null || alarmType === "psuMonitorAD-off"){
      return dataArray;
    }
    const unitSymbol = unit[unitSystem]?.[alarmType]?.symbol ?? "";
    const pureLatestValue = keyValues?.latestValues?.[alarmType];
    const latestValue = alarmType === "leakSensorADValue"
      ? t(prettifyLeakSensorADValue(pureLatestValue))
      : (unit[unitSystem]?.[alarmType]?.conv(pureLatestValue) ?? "? ") + unitSymbol
    
    if(keyValuesIsLoading){
      return dataArray.concat([[t("latest-value"), <CircularProgress size={15} />]]);
    }
    return dataArray.concat([[t("latest-value"), latestValue]]);
  }

  const alarmDataForList = addLatestValueRow([
    [t("device"), device.name],
    [t("location"), path],
    [t("time"), <PrettyTime>{alarm.alarmStamp}</PrettyTime>],
  ]);

  const openButton = () => {
    switch (openButtonType) {
      case "alarmIcon":
        return <AlarmIcon key={alarm.id + alarmType} type={alarmType} tooltipDisabled={tooltipDisabled} tooltipTitle={tooltipTitle ?? `${t("alarm")}: ${t(alarmType)}`} />
    
      default:  // "openIcon"
        return <Tooltip title={tooltipTitle}><InfoOutlinedIcon /></Tooltip>
    }
  }
  

  return (
    <>
      <IconButton onClick={() => setOpen(true)} >
        {openButton()}
      </IconButton>
      <RawDialog
        dialogOpen={open}
        data={{
          title: <>{t("alarm")}: {t(alarmType)} <AlarmIcon type={alarmType} sx={{mb: -0.7, ml: 1}} tooltipDisabled /></>,
          hideOpenButton: "true",
          okButtonText: "close",
          okButtonFunc: () => setOpen(false),
          //okButtonIsLoading: mutateLocationsIsLoading,
          //okButtonDisabled: name.length === 0,
          //cancelButtonText: "close",
          //cancelButtonFunc: () => setOpen(false),
          //cancelButtonIsLoading,
          }}
        >
        <DataList data={alarmDataForList} />
      </RawDialog>
    </>
  )
}
const AlarmDialog = withTranslation()(PureAlarmDialog);
export { 
  AlarmDialog 
};