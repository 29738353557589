import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

import { updateLanguage } from "../server/serverOperation.js";
import { languageSet, selectLanguage, selectUserPhoneId } from "../data/uiSlice.js";

import {
  FormControl,
  MenuItem,
  Select
} from '@mui/material/';

import logger from '../utility/logger.mjs';

const PureLangaugeSelector = ({t, sx}) => {
  const dispatch = useDispatch();
  const userPhoneId = useSelector(selectUserPhoneId);
  const language = useSelector(selectLanguage);

  const handleLangChange = (event) => {
    const newLanguage = event.target.value;
    i18next.changeLanguage(newLanguage);
    dispatch(languageSet(newLanguage));
    if(userPhoneId !== undefined){
      mutate({userPhoneId, language:newLanguage });
    } 
  }

  const { mutate, isLoading } = useMutation(
    updateLanguage, // parameters {userPhoneId, language}
    {
      onError: (error) => logger.error(error)
    }
  );

  return (
    <FormControl  size="small" variant="outlined" >
      <Select
        disabled={isLoading}
        labelId="language-selector"
        id="language-selector"
        value={language}
        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, ...sx}}
        onChange={ handleLangChange }
      >
        <MenuItem value={'fi'}>FI</MenuItem>
        <MenuItem value={'en'}>EN</MenuItem>
      </Select>
    </FormControl>
  );
}

const LangaugeSelector = withTranslation()(PureLangaugeSelector);
export { 
  LangaugeSelector 
};