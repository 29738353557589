import { withTranslation } from 'react-i18next';

import { AlertError } from "../components/Alert.js"

import { Box } from "@mui/material";
import { useDispatch } from 'react-redux';
import { loginTypeSet } from '../data/uiSlice.js';

const PurePageNotFound = () => {
  useDispatch()(loginTypeSet("landingPage"));
  return (
    <Box sx={{m:10}}>
      <AlertError text={"page-not-found-title"} title={false} />
    </Box>
  )
}

const PageNotFound = withTranslation()(PurePageNotFound);
export default PageNotFound;