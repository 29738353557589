import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import { currentPageSet } from "../data/uiSlice.js";
import {
  selectCurrentActivationTime,
  selectPrivileges,
} from '../data/devicesSlice.js';
import SettingsList from "../components/SettingsList.js";
import Phonenumbers from '../components/Phonenumbers/Phonenumbers.js'
import PinEditor from "../components/PinEditor.js";
import { AlertWarning } from "../components/Alert.js";
import DeviceHeader from "../components/Devices/DeviceHeader.js";
import {Divider} from "@mui/material";

function SubPageSettings({t}) {
  useDispatch()(currentPageSet("MAIN"));
  const pinEditEnabled = useSelector(selectPrivileges) !== "guest";
  const activationTime = useSelector(selectCurrentActivationTime);

  if(activationTime == null){
    return (
      <>
        <DeviceHeader sx={{mb: 1}} />
        <AlertWarning
          text={t("device-not-activated-warning")}
          title={false}
        />
      </>
    );
  }

  return (
    <>
      <DeviceHeader sx={{mb: 2}}/>
      <Divider sx={{mb: 2}} />
      <SettingsList />
      <Divider sx={{mt: 3, mb: 3}} />
      <Phonenumbers />
      { pinEditEnabled && <PinEditor /> }
    </>
  );
}

 export default withTranslation()(SubPageSettings);
