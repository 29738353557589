// Gives formated a timestamp.
const ts = () => {
  return new Date().toLocaleString('en-US', { hourCycle: 'h23' });
}

const info = (...params) => {
  console.log(ts(), ...params)
}

const error = (...params) => {
  console.error(ts(), ...params)
}

// Hides part of a given string. PARAMS: str: string to be masked;
// num: number of shown characters; mask: a character for replacing.
// Returns '[masked str]', if num >= str.length.
const mask = (str, num = 3, mask = '*') =>{
  if(num >= str?.length) return '[masked str]';
  return ('' + str).slice(0, -num).replace(/./g, mask) + ('' + str).slice(-num);
}

export default {
  info, error, mask
}