import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import logger from "../../utility/logger.mjs";
import { selectLocationById, locationDeleted } from "../../data/locationsSlice";
import { dropLocation } from "../../server/serverOperation";
import { AlertWarning } from "../Alert";
import useConfirm from "../ConfirmDialog";

import { useGridApiContext, GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Tooltip } from "@mui/material";

const PureLocationDeleteButton = ({t,locationId, showInMenu}) => {
  const dispatch = useDispatch();
  const apiRef = useGridApiContext();
  const confirm = useConfirm();
  const location = useSelector(selectLocationById(locationId))

  const {mutate:mutateDropLocation, isLoading:dropLocationIsLoading} = useMutation(
    dropLocation, // parameter {locationId}
    {
      onSuccess: (data) => {
        // move devices under the location to the top
        const locationDeviceIds = apiRef.current.getRow(locationId).devices;
        locationDeviceIds.forEach( deviceId => {
          apiRef.current.updateRows([{id:`d${deviceId}`, path: [`d${deviceId}`]}]);
        })
        apiRef.current.updateRows([{id:locationId, _action: 'delete' }]);
        dispatch(locationDeleted({id:locationId}));
        toast.success(t("location-deleted-info", {locationName: location.name}), {
          toastId: `location-deleted-${location.name}`
        });
      },
      onError: (error) => {
        toast.error(t("location-delete-error"), {
          toastId: "location-delete-error"
        });
        logger.error("updateLocation", error);
      }
    }
  );
  
  async function handleLocationDeleteClick(){
    if(location.children.length === 0){
      const confirmationOk = await confirm({
        children: <AlertWarning text={"location-delete-confirmation"} title={false} />,
        okButtonText: "ok",
        cancelButtonText: "cancel"
      });
      if(confirmationOk){
        mutateDropLocation({locationId});
      }
    }
    else {
      toast.warning(t("location-has-children-error"));
    }
  }
  if(showInMenu){
    return (
      <GridActionsCellItem 
        showInMenu
        label={t('delete-location')}
        icon={<DeleteForeverOutlinedIcon />} 
        onClick={handleLocationDeleteClick} 
      />
    )
  }

  return (
    <Tooltip title={t('delete-location')} >
      <GridActionsCellItem 
        icon={<DeleteForeverOutlinedIcon />} 
        onClick={handleLocationDeleteClick} 
      />
    </Tooltip>
  )
};
export default withTranslation()(PureLocationDeleteButton);
