import {useSelector} from "react-redux";
import {selectDeviceById} from "../../data/devicesSlice";
import DeviceStatusIcon from "../Icons/DeviceStatusIcon";

// Fist three are from DeviceDB.Device.deviceStatus enum list
export const devicesStatuses = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  UNKNOWN: "UNKNOWN",
  NOT_IN_USE_DEVICE_STATUS: "NOT_IN_USE_DEVICE_STATUS",
}

export function DeviceStatus({deviceId}){
  const device = useSelector(selectDeviceById(deviceId));
  const status = device.inUse
    ? devicesStatuses[device.deviceStatus]
    : devicesStatuses["NOT_IN_USE_DEVICE_STATUS"];
  return <DeviceStatusIcon status={status} />
}