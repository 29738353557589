import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

import { 
  currentDeviceSet,
  currentPageSet,
} from "../../data/uiSlice";
import { ActivateDevicesDialog } from "./ActivateDevicesDialog";

import {
  IconButton,
  lighten,
  Stack,
  Tooltip, 
} from "@mui/material";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Edit from "@mui/icons-material/Edit";

const useButtonHandler = ({deviceId, targetPage}) => {
  const dispatch = useDispatch();
  return(() => {
    dispatch(currentDeviceSet(deviceId));
    dispatch(currentPageSet(targetPage));
  });
}

const DeviceDataButton = withTranslation()(({t, deviceId}) => {
  return(
    <Tooltip title={t('device-data')} >
      <IconButton onClick={useButtonHandler({deviceId, targetPage: "DATA"})} >
        <InsertChartOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
})

const DeviceSettingsButton = withTranslation()(({t, deviceId}) => {
  return(
    <Tooltip title={t('device-settings')} >
      <IconButton onClick={useButtonHandler({deviceId, targetPage: 'MAIN'})} >
        <SettingsIcon />
      </IconButton>
    </Tooltip>
  );
})

const EditDeviceButton = withTranslation()(({t, toggleEditModeEnabled, editModeEnabled}) => {
  const iconBgColor = editModeEnabled ? (theme) => lighten(theme.palette.success.main, .8) : "";
  const iconColor = editModeEnabled ? "success" : "inherent";
  return(
    <Tooltip sx={{backgroundColor: iconBgColor}} title={t('edit')} >
      <IconButton  onClick={toggleEditModeEnabled} >
        <Edit color={iconColor} />
      </IconButton>
    </Tooltip>
  );
})

export default function DeviceActionButtons({deviceId, variant, toggleEditModeEnabled, editModeEnabled, hideAll, hideEditButton, hideDataButton, hideSettingsButton}){
  if(hideAll){
    return false;
  }

  if(variant === "activateDevice"){
    return ([
      <ActivateDevicesDialog openButtonType={"plus"} preselectedDeviceIds={[deviceId]}/>
    ]);
  }
  if(variant === "array"){
    return ([
      <DeviceDataButton deviceId={deviceId} />,
      <DeviceSettingsButton deviceId={deviceId} />,
    ]);
  }
  // default: variant === "Stack"
  return (
    <Stack 
      direction="row"
      spacing={1} 
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        }} 
      >
      { !hideEditButton && <EditDeviceButton toggleEditModeEnabled={toggleEditModeEnabled} editModeEnabled={editModeEnabled} /> }
      { !hideDataButton && <DeviceDataButton deviceId={deviceId}/> }
      { !hideSettingsButton &&  <DeviceSettingsButton deviceId={deviceId} /> }
    </Stack>
  );
}