import {withTranslation} from "react-i18next";

import {Button} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const DELIMITER = ";";

// PARAM: headers: an array of strings; data: an array of objects
// Data is filtered with given headers.
// Notice: in influx data, timestamp goes with label "name" --> these are added by default
const PureDownloadCSVButton = ({t, data, headers, fileName, disabled }) => {
  const downloadCSV = () => {
    const csvHeaders = [t('time')].concat(headers.map(header => t(header)));
    const csvString = [
      csvHeaders,
      ...data.map(row => ["name", ...headers].map(header => row[header] ))
    ]
      .map(row => row.join(DELIMITER))
      .join("\n");

    const blob = new Blob([csvString], { type: 'text/csv' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={ downloadCSV }
      variant="outlined"
      disabled={disabled}
      endIcon={<DownloadIcon />}
    >
      {t('download-report-csv')}
    </Button>
  )
};
const DownloadCSVButton = withTranslation()(PureDownloadCSVButton);
export default DownloadCSVButton;