import { useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';
import useParamSelector from "../data/useParamSelector";

import { 
  currentSettingsSet,
  selectCurrentComponentDesc,
  selectComponentValueObject
} from "../data/devicesSlice";

import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material/';
import useScreenSize from "../utility/useScreenSize";

// If device's settings don't match buttons off definition, consider button enabled.
const calculateInitialValues = (componentValues,buttons) => {
  let values = [];
  for(const button of buttons){
    for(const [key,offValue] of Object.entries(button.value.off)){
      if(componentValues?.[key] == null || componentValues[key] !== offValue){
        values.push(button.label);
        break;
      }
    }
  }
  return values;
}

function PureToggleButtons({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  const buttons = desc?.BUTTONS ?? [];
  const title = desc?.TITLE;
  const valInd = data?.JSONValInd;
  const componentValues = useParamSelector(selectComponentValueObject, componentId);
  const [values, setValues] = useState(calculateInitialValues(componentValues,buttons));

  const handleChange = (event, newValue) => {
    let changeType = "";
    let label = "";
    if(values.length > newValue.length){
      changeType = "off";
      label = values.filter(key => !newValue.includes(key))[0];
    }
    else {
      changeType = "on";
      label = newValue.filter(key => !values.includes(key))[0];
    }
    const settingsArray = Object.entries(desc.BUTTONS.find( row => row["label"] === label).value[changeType]);
    const settingsValues = settingsArray.map( r => r[1]);
    const settingsJsonkeys = settingsArray.map( r => r[0]);
    dispatch(currentSettingsSet({
      values: settingsValues,
      jsonkeys: settingsJsonkeys,
      valInd
    }));
    setValues(newValue);
  };

  // If there are more buttons than can fit in the screen, render groups of them into different rows.
  const buttonRowMax = useScreenSize().windowIsNarrow ? 4 : 8;
  const numberOfGroups = Math.ceil(buttons.length / buttonRowMax);
  const buttonGroups = new Array(numberOfGroups)
    .fill('')
    .map((_, i) => buttons.slice(i * buttonRowMax, (i + 1) * buttonRowMax));

  return (
    <FormControl >
      <FormLabel sx={{textAlign: "left", marginLeft: "1em"}}>{t(title)}</FormLabel>
      { buttonGroups.map(buttonsGroup => (
        <ToggleButtonGroup
          sx={{mt: 1, ml: 2}}
          size="large"
          disabled={disabled}
          value={values}
          color="primary"
          onChange={handleChange}
        >
          {buttonsGroup.map(b => (
            <ToggleButton
              key={b.label}
              value={b.label}
            >
              <Tooltip title={t(b.label)}>
                {t(`${b.label}-abbreviation`)}
              </Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ))}
    </FormControl>
  );

};

const ToggleButtons = withTranslation()(PureToggleButtons);

export {
  ToggleButtons
};