import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material/';

const formatDataObject = (key, value, color="default") => {
  return {key: `${key}:`, value, color}
}

const formatData = (dataArr) => {
  return dataArr.map( row => formatDataObject(...row))
}

// A simple list (or table) of data.
// PARAMS:
// data: an array of key/value pairs [["name","Pekka"],["age",3],...].
// key: string that is used to generate unique item keys
function DataList({data, key:generalKey, hideLastLine }) { 
  const formattedData = formatData(data);
  const lastRowIndex = data.length - 1;
  const disableLastVerticalLine = hideLastLine ? { borderBottom: 0} : {};

  return (
    <Table size="small" aria-label="purchases">
      <TableBody>
        {formattedData.map( (row,i) => 
          <TableRow key={generalKey + row.key} >
            <TableCell align="left" sx={{ width: "25%",  ...(i === lastRowIndex ? disableLastVerticalLine : {}) }}>
              {row.key}
            </TableCell>
            <TableCell align="left" sx={(i === lastRowIndex ? disableLastVerticalLine : {})} >
              <span style={ {color: row.color, ...(i === lastRowIndex ? disableLastVerticalLine : {}) }}> 
                {row.value} 
              </span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export {
  DataList
};