import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import logger from "../../utility/logger.mjs";
import { locationAdded, selectAllLocationTypes } from "../../data/locationsSlice";
import { selectCurrentProjectId } from "../../data/uiSlice";
import { RawDialog } from "../InfoDialog";
import { addLocation } from "../../server/serverOperation";
import { LocationTypeSelect } from "./LocationTypeSelect";
import { RawLocationSelect } from "./LocationSelect";
import { DataList } from "../DataList/DataList";

import { 
  GridActionsCellItem,
  useGridApiContext 
} from "@mui/x-data-grid-pro";

import { 
  LOCATION_NAME_LENGTH_MAX, 
  LOCATION_INFO_LENGTH_MAX 
} from '../../utility/locationConfig.mjs'
import AddIcon from '@mui/icons-material/Add';

import { TOP_LOCATION_IND } from "./locationTableSettings";
import TextInput from "../DataList/TextInput";
import { Tooltip } from "@mui/material";

const PureLocationAddDialog = ({t, locationId, openButtonType, showInMenu, dataGridAction}) =>  {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const types = useSelector(selectAllLocationTypes);
  const initLocationType = types[0];

  const [menuOpenState, setMenuOpenState] = useState(false);

  const [newLocationName, setNewLocationName] = useState('');
  const [newLocationType, setNewLocationType] = useState(initLocationType);
  const [newLocationInfo, setNewLocationInfo] = useState('');
  const [newParentLocationId, setNewParentLocationId] = useState(locationId ?? TOP_LOCATION_IND);

  const { mutate:mutateLocations, isLoading:mutateLocationsIsLoading } = useMutation(
    addLocation, // parameter {projectId, parentId, name, type, info}
    {
      onSuccess: (data) => {
        const newLocationId = data.insertId;
        const addedLocation = {
          id: newLocationId,
          projectId,
          parentId: newParentLocationId,
          name: newLocationName,
          type: newLocationType,
          info: newLocationInfo,
        }
        const newPath = newParentLocationId !== TOP_LOCATION_IND
          ? apiRef.current.getRow(newParentLocationId).path.concat([newLocationId])
          : [newLocationId];

        // FOR REDUX SLICE:
        dispatch(locationAdded(addedLocation));
        // FOR DATA GRID: update the row of the target location
        apiRef.current.updateRows([{...addedLocation, path: newPath}]);

        setNewLocationName("");
        setNewLocationType(initLocationType);
        setNewLocationInfo("");

        toast.success(t("location-added-info",{locationName: newLocationName}), {
          toastId: `added-${newLocationName}`
        });
      },
      onError: (error) => {
        toast.error(t("location-add-error"), {
          toastId: "location-add-error"
        });
        logger.error("addLocation", error);
      }
    }
  );

  const disabled = mutateLocationsIsLoading;

  // List of information about the location.
  // Array of rows with of a header and conents.
  const locationDataForList = [
    [
      t("location-name"), 
      <TextInput 
        value={newLocationName}
        disabled={disabled}
        placeholder={t('location-name-placeholder')}
        onChange={e => setNewLocationName(e.target.value)}
        inputProps={{maxLength: LOCATION_NAME_LENGTH_MAX}}
      />
    ],
    [
      t("location-info"),
      <TextInput 
        placeholder={t('location-info-placeholder')}
        value={newLocationInfo}
        disabled={disabled}
        onChange={e => setNewLocationInfo(e.target.value)}
        inputProps={{maxLength: LOCATION_INFO_LENGTH_MAX}}
      /> 
    ],
    [
      t("type"),  
      <LocationTypeSelect
        sx={{width:"100%"}}
        type={newLocationType} 
        onChange={e => setNewLocationType(e.target.value)}
      />
    ],
    [
      t("position"), 
      <RawLocationSelect 
        sx={{width:"100%"}}
        value={newParentLocationId}
        onChange={e => setNewParentLocationId(e.target.value)}
      />
    ]
  ];
  
  const handleAddClick = (e) => {
    if(newLocationName.length > 0){
      mutateLocations({
        projectId,
        parentId: newParentLocationId,
        name: newLocationName,
        type: newLocationType,
        info: newLocationInfo,
      });
    } 
  }

  const dialogData = {
    title: "add-location",
    openButtonType,
    openButtonText: "add-location",
    openButtonColor: "success",
    okButtonText: "add",
    okButtonFunc: handleAddClick,
    okButtonIsLoading: mutateLocationsIsLoading,
    okButtonDisabled: newLocationName.length === 0,
    cancelButtonText: "close",
    //cancelButtonFunc,
    //cancelButtonIsLoading,
  };

  if(dataGridAction){
    return (
      <>
        <Tooltip title={showInMenu ? null : t('add-sublocation')} >
          <GridActionsCellItem 
            showInMenu={showInMenu}
            label={t('add-sublocation')}
            icon={<AddIcon />} 
            onClick={() => setMenuOpenState(true)} 
          />
        </Tooltip>
        <RawDialog
          dialogOpen={menuOpenState}
          data={{
            ...dialogData,
            hideOpenButton: true,
            cancelButtonFunc: () => setMenuOpenState(false)
            }}
        >
        <DataList 
          data={locationDataForList}
          key={locationId} 
        />
        </RawDialog>
      </>
    )
  }

  return (
    <RawDialog
      openButtonSX={{m: 0}}
      data={dialogData}
    >
    <DataList 
      data={locationDataForList}
      key={locationId} 
    />
    </RawDialog>
  )
}
const LocationAddDialog = withTranslation()(PureLocationAddDialog);
export default LocationAddDialog;