import { useSelector } from "react-redux";
import { Duration, DateTime } from "luxon";

import { 
  selectLanguage,
  selectHourCycle,
} from "../../data/uiSlice.js";
import { selectCurrentUTCzoneOffset } from "../../data/devicesSlice.js";
import { formatTime } from "./time.mjs";

export const PrettyTime = ({children:timeStr, format:rawFormat}) => {
  const timeRangeName = useSelector(state => state.ui.timeRange.name );
  const offset = useSelector(selectCurrentUTCzoneOffset);
  const language = useSelector(selectLanguage);
  const hourCycle = useSelector(selectHourCycle);
  if(timeStr == null){
    return false;
  }
  const format = rawFormat === "timeRange"
    ? timeRangeName
    : rawFormat 

  return formatTime(timeStr, format, language, hourCycle, offset);  
};

export const TimeLeftTo = ({children:timeStr, duration}) => {
  const language = useSelector(selectLanguage);
  const rawTimeLeft = DateTime
      .fromISO(timeStr)
      .setLocale(language)
      .plus(duration)
      .diffNow(["years","months","days"]);
  const timeLeft = rawTimeLeft.set({days: Math.floor(rawTimeLeft.days) });
  return timeLeft >= 0 ? timeLeft.toHuman() : 0;
}