import {useState, useCallback, useRef, useLayoutEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { activateDevices } from '../../server/serverOperation.js';
import {devicesPhonenumbersSet, devicesUseStatusSet, selectAllDevices,} from '../../data/devicesSlice.js';
import { selectLanguage,  } from "../../data/uiSlice.js";
import PhonenumberInput from "../Phonenumbers/PhonenumberInput.js";
import { ButtonSmall } from "../Button.js";
import { HelpText } from '../ReadMore.js';
import useScreenSize from "../../utility/useScreenSize";
import {TimeZoneSelector} from "../Time/TimeZone";
import DeviceTypeIcon from "../Icons/DeviceTypeIcon.js";

import {
  AppBar, Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, styled,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Stack, tooltipClasses,
  Container,
} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from "@mui/icons-material";
import useDataGridLocaleText from "../Locations/useDataGridLocaleText.js";
import {DataGridPro, GridEditInputCell, useGridApiContext, useGridApiRef} from '@mui/x-data-grid-pro';

import logger from "../../utility/logger.mjs";
import {DEVICE_NAME_LENGTH_MAX} from "../../utility/deviceConfig";
import {AlertError} from "../Alert";

const userTypes = ['superadmin']

// For validating inserted device name
// const preProcessEditCellProps = (params) => {
//   const insertedNameLength = params.props.value.length
//   console.log(params)
//   const hasError = insertedNameLength < 4 || insertedNameLength > DEVICE_NAME_LENGTH_MAX;
//   if(!hasError){
//     setNewDevicesNames(prevState => {prevSate    });
//   }
//   return { ...params.props, error: hasError ? 'Invalid device name' : null };
// }
//
// const StyledTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.error.main,
//     color: theme.palette.error.contrastText,
//   },
// }));

// function NameEditInputCell(props) {
//   const { error } = props;
//
//   return (
//     <StyledTooltip open={!!error} title={error}>
//       <GridEditInputCell {...props} />
//     </StyledTooltip>
//   );
// }


//
// function renderEditName(params) {
//   return <NameEditInputCell {...params} />;
// }

function CustomEditComponent(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    const valueIsValid = newValue.length <= DEVICE_NAME_LENGTH_MAX;
    if(valueIsValid){
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };

  return <input ref={ref} type="text" value={value} onChange={handleValueChange} />;
}

const renderCustomEditComponent = (params) => {
  return <CustomEditComponent {...params} />;
};

// device table column definitions
const columns = [
  {
    field: 'type',
    headerName: '', //t('type'),
    width: 40,
    display: 'flex',
    align: 'center',
    // render device type as an icons
    renderCell: row =>  <DeviceTypeIcon type={row.value} />,
  },
  {
    field: 'name',
    headerName: 'name',
    flex: 2,
    // TODO: edit device names
    //editable: true,
    //preProcessEditCellProps,
    //renderEditCell: renderCustomEditComponent,
  },
  {
    field: 'uuid',
    headerName: 'id',
    flex: 1.2,
    editable: false,
  },
];


const PureActivateDevicesDialog = ({t, openButtonType, preselectedDeviceIds=[]}) => {
    const dispatch = useDispatch();
    const apiRef = useGridApiRef();
    const language = useSelector(selectLanguage);
    const [superuserPhonenumber, setSuperuserPhonenumber] = useState({phonenumber: "", country: ""});
    //const [newDevicesNames, setNewDevicesNames] = useState(null);
    const [utcZoneOffset, setUTCzoneOffset] = useState(0);
    const [pinCode, setPinCode] = useState("");
    const [pinInputColor, setPinInputColor] = useState("grey");
    const devices = useSelector(selectAllDevices).filter(device => !device.inUse);
    const [selectedDeviceIds, setSelectedDeviceIds] = useState(preselectedDeviceIds);
    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);

    const handlePinChange = (e) => {
      const newPin = e.target.value;
      const valueIsValid =
        newPin === ""
        || (/^[0-9]+$/.test(newPin) && newPin.length <= 6)
      if (valueIsValid){
        setPinCode(newPin);
        setPinInputColor(pinCodeColorByLength[newPin.length] ?? "red");
      }
    }

    const pinCodeColorByLength = {
      0: "grey",
      6: "green",
    }
    
    const handleNextClick = () => {
      const phonenumberIsOk =
        superuserPhonenumber.phonenumber.length > 0
        && superuserPhonenumber.country.length > 0;
      const pinCodeIsOk = pinCode.length === 6
      if(!phonenumberIsOk){
        toast.error(t("setup-number-error"), {
          toastId: "setup-number-error"
        });
      }
      if(!pinCodeIsOk){
        toast.error(t("pin-error"), {
          toastId: "pin-error"
        });
      }
      if(selectedDeviceIds.length === 0){
        toast.error(t("No devices selected!"), {
          toastId: "devices-not-selected-error"
        });
      }
      if(phonenumberIsOk && pinCodeIsOk && selectedDeviceIds.length > 0){
        // close all toasts
        toast.dismiss();
        setOpenSecond(true);
      }
    }

    const { mutate:mutateActivateDevices, isLoading:activateDevicesIsLoading } = useMutation(
      activateDevices,
      {
        onSuccess: (data) => {
          if(data.updatedDeviceData.length > 0){
            dispatch(devicesPhonenumbersSet(data.updatedDeviceData));
            dispatch(devicesUseStatusSet(data.updatedDeviceData));
            const activatedDeviceNames = devices
              .filter(device => selectedDeviceIds.includes(device.id))
              .map(device => device.name)
              .join(', ');
            toast.success(t('devices-activated-info', {deviceNames: activatedDeviceNames}), {
              toastId: 'devices-activated'
            });
          }
        },
        onError: (error) => {
          toast.error(t('activate-devices-error'), {
            toastId: 'activate-devices-error'
          });
          logger.error("activateDevices",error);
        }
      }
    );

    const openFirstDialog = () => {
      setOpenFirst(true);
      setOpenSecond(false);
    }

    const closeBothDialogs = () => {
      setOpenFirst(false);
      setOpenSecond(false);
    }

  const handleConfirmClick = () => {
    // TODO: edit device names
      //   // Consider only names of selected devices
    // const relevantNewDevicesNames = Object.fromEntries(
    //   Object.entries(newDevicesNames).filter(([deviceId,_]) => selectedDeviceIds.include(deviceId))
    // );
    mutateActivateDevices({
      deviceIds: selectedDeviceIds,
      pinCode,
      superuserPhonenumber: {...superuserPhonenumber, alarmCall:false, userType:'superadmin'},
      restPhonenumbers: [],
      //newDevicesNames: relevantNewDevicesNames,
      utcZoneOffset
    })
    closeBothDialogs();
  }

    const OpenButton = ({type}) => {
      if(type === "plus"){
        return(
          <Tooltip title={t('activate-device')} >
            <IconButton onClick={openFirstDialog}>
              <Add />
            </IconButton>
          </Tooltip>
        )
      }
      return (
        <Button variant="outlined" onClick={openFirstDialog}>
          {t('activate-devices-dialog-button')}
        </Button>
      )
    }

  const deviceCount = devices.length;
    const headerHeight = 41;
    const rowHeight = 36;
  const tableHeight = Math.min(400, headerHeight + (deviceCount * rowHeight));

  // TODO: saving name to state resets table value for some reason
  // When user edits a name of a device in the table cell, save it to state.
  // const processNameUpdate = useCallback((updatedRow,prevRow) => {
  //   console.log("processNameUpdate",updatedRow.name,prevRow.name)
  //   const {id, name} = updatedRow;
  //   setNewDevicesNames(prevState => ({...prevState, [id]: name}));
  //    return updatedRow;
  // },[])
  //
  // const handleProcessRowUpdateError = useCallback((error) => {
  //   console.log("handleProcessRowUpdateError",error);
  // }, []);

  return (
    <>
      <OpenButton type={openButtonType} />
      <Dialog
        fullScreen
        open={openFirst}
        onClose={closeBothDialogs}
      >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeBothDialogs}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("activate-devices")}
          </Typography>
        </Toolbar>
      </AppBar>
        <DialogContent >
          <Container maxWidth="sm" >
            {t("activate-devices-dialog-info-1")}
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
            <PhonenumberInput
              number={superuserPhonenumber.phonenumber}
              setNewNumber={setSuperuserPhonenumber}
              useType={userTypes[0]}
              setUserType={() => {}}
              userTypes={userTypes}
              inDialog
              defalutCountry={language}
              disabled={activateDevicesIsLoading}
              />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
            <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
            <HelpText
                text={t("setup-dialog-info-2-more")}
                buttonStyle={{float:"right", marginTop: -65}}
                />
            <TextField
              id="newPin"
              sx={{ input: { color: pinInputColor }} }
              type="text"
              name="newPint"
              placeholder={t("create-new-pin")}
              value={pinCode}
              onChange={handlePinChange}
              size="small"
              disabled={activateDevicesIsLoading}
            />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("time-zone")}</Typography>
            <TimeZoneSelector
              value={utcZoneOffset}
              onChange={setUTCzoneOffset}
              disabled={activateDevicesIsLoading}
            />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("inactivated-devices")}</Typography>
            <Box sx={{height: tableHeight, mx: useScreenSize().windowIsNarrow ? -4 : 0 }} >
              <DataGridPro
                apiRef={apiRef}
                localeText={useDataGridLocaleText()}
                rows={devices}
                columns={columns}
                density='compact'
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={useCallback((newSet) => {
                  setSelectedDeviceIds(newSet);
                },[])}
                rowSelectionModel={selectedDeviceIds}
                //processRowUpdate={processNameUpdate}
                // onProcessRowUpdateError={handleProcessRowUpdateError}
                hideFooter
                disableColumnMenu
              />
            </Box>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              m: 2,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonSmall
              width="26%"
              text="cancel"
              onClick={closeBothDialogs}
              isLoading={activateDevicesIsLoading}
            />
            <ButtonSmall
              width="26%"
              text="next"
              onClick={handleNextClick}
              isLoading={activateDevicesIsLoading}
            />
            </Stack>
          </Container>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSecond}
      >
        <DialogTitle color="secondary.main">
          {t("activate-devices")}
        </DialogTitle>
        <DialogContent>
          <p>{t("activate-devices-dialog-2-info-1")}</p>
          <DataGridPro
            localeText={useDataGridLocaleText()}
            density='compact'
            columns={columns}
            rows={devices.filter(device => selectedDeviceIds.includes(device.id))}
            hideFooter
          />
          <p>{t("phonenumber")}: <b>{superuserPhonenumber.phonenumber}</b> </p>
          <p>{t("activate-devices-dialog-2-info-2")}</p>
        </DialogContent>
        <DialogActions>
          <ButtonSmall
            margin={"-15px 10px 20px 0px"}
            width="26%"
            text="back"
            onClick={openFirstDialog}
            isLoading = { activateDevicesIsLoading }
          />
            <ButtonSmall
            margin={"-15px 10px 20px 0px"}
            width="26%"
            text="activate"
            onClick={handleConfirmClick}
            isLoading = { activateDevicesIsLoading }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
const ActivateDevicesDialog = withTranslation()(PureActivateDevicesDialog);
export { 
  ActivateDevicesDialog 
};