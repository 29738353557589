import { 
  createSlice, 
  createAsyncThunk, 
  createSelector, 
} from "@reduxjs/toolkit";
import { phonenumberSortFunc } from "../utility/userConfig.mjs";
import { 
  selectCurrentDeviceId, 
  selectUserPhoneId,
  selectCurrentProjectId
} from './uiSlice.js';
import { 
  selectCurrentProjectLocations 
} from "./locationsSlice.js";
import {
  fetchDeviceInfoAxios,
  fetchPhonenumbers2,
  sendNewSettings
} from "../server/serverOperation.js";

const initialState = { 
  device: [],
  test: [],
  status: 'idle', // 'idle' | 'loading' | 'succeed' | 'failed'
  error: null
};

export const currentSettingsSet = createAsyncThunk(
  'devices/currentSettingsSet',
  async ({values, jsonkeys, valInd }, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    const commTech = getState().devices.device[i].commTech;
    const newSettings = formatAlteredSettings(
      getState().devices.device[i].newSettings, 
      jsonkeys, 
      valInd, 
      values,
      commTech
    )
    return { i, newSettings, jsonkeys };
  }
);

export const currentNewSettingsRestored = createAsyncThunk(
  'devices/currentNewSettingsRestored',
  async (_, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    return { i };
  }
);

export const currentPhonenumbersSet = createAsyncThunk(
  'devices/currentPhonenumbersSet',
  async ({newPhonenumbers}, { getState }) => {
    const deviceId = getState().ui.currentDeviceId;
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    return { i, newPhonenumbers };
  }
);

export const getDeviceData = createAsyncThunk(
  'devices/getDeviceData', 
  async (deviceUUID) => {
    const res = await fetchDeviceInfoAxios(deviceUUID);
    return res;
  }
);

export const getPhoneNumbers = createAsyncThunk(
  'devices/getPhoneNumbers', 
  async (deviceUUID) => {
    const res = await fetchPhonenumbers2(deviceUUID);
    return res;
  }
);

// Get device's settings by its deviceId and write them into the DB.
export const sentSettings = createAsyncThunk(
  'devices/sendNewSettings', 
  async ( deviceId, { getState, rejectWithValue } ) => {
    const i = getState().devices.device.findIndex( d => d.id === deviceId );
    const commTech = getState().devices.device[i].commTech;
    const newSettingsId = getState().devices.device[i].settingsId + 1;

    const settingsToBeSent = getState().devices.device[i].settingsToBeSent;
    if(JSON.stringify(settingsToBeSent) === '{}'){
      return rejectWithValue("no settings to be sent")
    }
    try{
      const res = await sendNewSettings(deviceId, newSettingsId, settingsToBeSent, commTech);
      return {i, data: res.data};
    }
    catch (err) {
      if (!err.res) {
        throw err
      }
      return rejectWithValue({i, error: err.res.data})
    }
  }
);

// Construct a settings object in a right format for given commTech.
const formatAlteredSettings = (oldSettings, jsonkeys, valInd, values, commTech) => {
  // ** LTE-M **
  // Settings of a LTE-M devices are stored an object with arrays, e.g {jsonkey1: [0,4,2,4,5], jsonkey2: [3,4,5,3,2], ...}.
  // When a settings is set, it must be inserted into the array indicated with jsonkey
  // and into the right place determined by valInd. Value should be a number: it is converted..
  if(commTech === "LTE-M"){
    return Object.assign({}, oldSettings,...jsonkeys.map( (jsonkey, i) => (
      {
        [jsonkey]: Object.assign([], oldSettings[jsonkey], {[valInd]: Number(values[i])})
      }
    )));
  }
  // ** Sigfox **
  // Settings of a Sigfox are in object format, e.g. {jsonkeys1: 0, jsonkeys2: 80, ...}.
  // Value should be a number: it is converted..
  return Object.assign({}, ...jsonkeys.map( (jsonkey, i) => ({[jsonkey]: Number(values[i])})));
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
      deviceAdded: (state, action) => {
        let deviceInd = state.device.findIndex( d => d.id === action.payload.id );
        deviceInd = deviceInd === -1 ? 0 : deviceInd;
        state.device[deviceInd] = {
            id:               action.payload?.id,
            name:             action.payload?.name,
            type:             action.payload?.type,
            typeId:           action.payload?.typeId,
            uuid:             action.payload?.uuid,
            phonenumbers:     action.payload?.phonenumbers?.toSorted(phonenumberSortFunc),
            settings:         action.payload?.settings,
            msgId:            action.payload?.msgId,
            settingsTime:     action.payload?.settingsTime,
            newSettings:      action.payload?.settings,
            pendingSettings:  action.payload?.pendingSettings,
            settingsToBeSent: {},
            settingsSentTime: null, 
            settingsId:       action.payload?.settingsId ?? 0,
            uiGeneralDesc:    action.payload?.uiGeneralDesc,
            uiDesc:           action.payload?.uiDesc,
            data:             action.payload?.data, //|| {referenceValues: {}, showAdvanced: {}},
            privileges:       action.payload?.privileges ?? "user",
            dataIsLoaded:     true,
            settingsState:    "notChanged", // "changed" | "notChanged" | "pending" | "error"
            simid:            action.payload?.simid,
            activationTime:   action.payload?.activationTime,
            country:          action.payload?.country,
            utcZoneOffset:    action.payload?.utcZoneOffset,
            deviceStatus:     action.payload?.deviceStatus,
            brand:            action.payload?.brand,
            commTech:         action.payload?.commTech,
            inUse:            action.payload.inUse ?? 0,
            projectId:         action.payload.projectId ?? null,
          };
          if(state.device[deviceInd].uiDesc) {
            const influxKeys = [...(new Set(state.device[deviceInd].uiDesc.map((row) => row.influxKey)))];
            state.device[deviceInd].data.showAdvanced = Object.fromEntries(influxKeys.map(k => [k, false]));
          }
      },
      devicesAdded: (state, action) => {
        state.device = action.payload;
      },
      // action.payload == { deviceId, jsonkeys, jsonValInd, newValues }
      setDeviceSettings: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.id );
        const newSettings  = formatAlteredSettings(
            state.device[i].newSettings, 
            action.payload.jsonkeys, 
            action.payload.jsonValInd, 
            action.payload.values
          );
        state.device[i].newSettings = {...state.device[i].newSettings, newSettings};
        state.device[i].settingsState = "changed";
      },
      showAdvancedSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.id );
        const category = action.payload.category;
        const value = action.payload.value;
        state.device[i].data.showAdvanced[category] = value;
      },
      newSettingsRestored: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload );
        state.device[i].newSettings = state.device[i].settings;
      },
      // settingsToBeSentReseted: (state, action) => {
      //   const i = state.device.findIndex( d => d.id === action.payload);
      //   state.device[i].settingsToBeSent = {};
      // },
      settingsSentTimeStampSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId);
        state.device[i].settingsSentTime = action.payload.timeStamp;
      },
      phonenumbersSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        const numbers = action.payload?.phonenumbers.toSorted(phonenumberSortFunc);
        state.device[i].phonenumbers = numbers;
      },
      devicesPhonenumbersSet: (state, action) => {
        for(const row of action.payload){
          const i = state.device.findIndex( device => device.id === row.deviceId );
          const numbers = row.phonenumbers.toSorted(phonenumberSortFunc);
          state.device[i].phonenumbers = numbers;
        }
      },
      devicesUseStatusSet: (state, action) => {
        for(const row of action.payload){
          const i = state.device.findIndex( device => device.id === row.deviceId );
          state.device[i].inUse = row.inUse;
        }
      },
      deviceNameSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        state.device[i].name = action.payload.newName;
      },
      deviceUTCzoneOffsetSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        state.device[i].utcZoneOffset = action.payload.newUTCzoneOffset;
      },
      privilegesSet: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload.deviceId );
        state.device[i].privileges = action.payload.privileges;
      },
      deviceSettingsRefreshed: (state, action) => {
        const i = state.device.findIndex( d => d.id === action.payload );
        state.device[i].newSettings = {...state.device[i].newSettings};
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getDeviceData.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getDeviceData.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.test.push(action.payload.data[0]);
        })
        .addCase(getDeviceData.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(getPhoneNumbers.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getPhoneNumbers.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.test.push(action.payload.json());
        })
        .addCase(getPhoneNumbers.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(currentSettingsSet.fulfilled, (state, action) => {
          const i = action.payload.i;
          state.device[i].newSettings = {
            ...state.device[action.payload.i].newSettings, 
            ...action.payload.newSettings
          };
          state.device[i].settingsState = "changed";
          state.device[i].settingsToBeSent = {
            ...state.device[i].settingsToBeSent, 
            ...action.payload.newSettings
          }
        })
        .addCase(currentNewSettingsRestored.fulfilled, (state, action) => {
          const i = action.payload.i;
          state.device[i].newSettings = state.device[i].settings;
          //state.device[action.payload.i].settingsToBeSent = {};
        })
        .addCase(currentPhonenumbersSet.fulfilled, (state, action) => {
          state.device[action.payload.i].phonenumbers = action.payload?.newPhonenumbers.toSorted(phonenumberSortFunc);  
        })
        .addCase(sentSettings.pending, (state, action) => {
          state.status = "loading";  
        })
        .addCase(sentSettings.fulfilled, (state, action) => {
          state.device[action.payload.i].settingsState = "notChanged";
          state.device[action.payload.i].settingsToBeSent = {};
        })
        .addCase(sentSettings.rejected, (state, action) => {
          //state.device[action.payload.i].settingsState = "error";  
          state.error = action?.payload?.error;
        })
    } 
});

export const selectDeviceById = id => state => state.devices.device.find( dev => dev.id === id);

export const selectAllDevices = (state) => state.devices.device;

// Returns the index of the current device in the device array.
// Returns -1 if the index is not found.
export const selectCurrentDeviceIndex = createSelector(
  [selectCurrentDeviceId, selectAllDevices], 
  (deviceId, devices) => {
    const i = devices.findIndex( d => d.id === deviceId );
    return i !== -1 ? i : 0;
  }
);

export const selectCurrentDevice = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]
);

export const selectCurrentProjectDevices = createSelector(
  [selectAllDevices, selectCurrentProjectId],
  (devices, currentProjectId) => devices.filter( d => (d.projectId === currentProjectId))
);

export const selectCurrentProjectDeviceIds = createSelector(
  [selectAllDevices, selectCurrentProjectId],
  (devices, currentProjectId) => devices.filter( d => (d.projectId === currentProjectId)).map( d => d.id)
);

export const selectCurrentProjectFreeDevices = createSelector(
  [selectCurrentProjectDevices, selectCurrentProjectLocations],
  (devices, locations) => {
    const boundDeviceIds = new Set(locations.reduce( (a,c) => a.concat(c.devices), []));
    return devices.filter( d => !boundDeviceIds.has(d.id));
  }
);

export const selectPrivileges = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.privileges
);

export const selectCurrentDataIsLoaded = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.dataIsLoaded
);

export const selectCurrentActivationTime = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.activationTime
);

export const selectCurrentReferenceTime = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.data?.referenceValues?.time
);

export const selectCurrentReferenceValuesEnabled = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => !!devices?.[index]?.uiGeneralDesc?.referenceValuesEnabled
);

export const selectCurrentTimeRangeButton = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => !!devices?.[index]?.uiGeneralDesc?.timeRangeButton
);

export const selectCurrentUTCzoneOffset = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.utcZoneOffset
);

export const selectCurrentNewSettingsId = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.newSettingsId
);

export const selectCurrentNewSettings = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.newSettings
);

// export const selectCurrentSettingsToBeSent = createSelector(
//   [selectCurrentDeviceIndex, selectAllDevices],
//   (index, devices) => devices[index].settingsToBeSent
// );

export const selectCurrentSettingsState = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.settingsState
);

export const selectCurrentSettingsSentTimeStamp = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.settingsSentTime
);

export const selectCurrentData = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.data
);

export const selectCurrentPhonenumbers = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.phonenumbers
);

export const selectUserPhonenumber = createSelector(
  [selectUserPhoneId, selectAllDevices],
  (id, devices) => devices?.[0]?.phonenumbers?.find( n => n.id === id)?.phonenumber
);

export const selectCurrentCountry = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.country
);

export const selectCurrentcommTech = createSelector(
  [selectCurrentDeviceIndex, selectAllDevices],
  (index, devices) => devices?.[index]?.commTech
);

export const selectCurrentComponentDesc = createSelector(
  [
    selectCurrentDevice,
    (_, componentId) => componentId
  ],
  (device, componentId) => device?.uiDesc?.find( o => o.id === componentId)
);

export const selectComponentValues = createSelector(
  [
    selectCurrentDevice,
    (_, componentId) => componentId
  ],
  (device, componentId) => {
    const jsonkeys = device.uiDesc.find( o => o.id === componentId).jsonkeys;
    if(device.commTech === "LTE-M"){
      const valInd = device.uiDesc.find( o => o.id === componentId).JSONValInd;
      return jsonkeys?.map( jsonkey => device.newSettings?.[jsonkey]?.[valInd] ?? null ) || [];
    }
    // device.commTech === "Sigfox"
    return jsonkeys?.map( jsonkey => device.newSettings?.[jsonkey] ?? null ) || [];
  }
);

export const selectComponentValueObject = createSelector(
  [
    selectCurrentDevice,
    (_, componentId) => componentId
  ],
  (device, componentId) => {
    const jsonkeys = device.uiDesc.find( o => o.id === componentId).jsonkeys;
    if(device.commTech === "LTE-M"){
      const valInd = device.uiDesc.find( o => o.id === componentId).JSONValInd;
      return Object.fromEntries(jsonkeys.map( key => [key, device.newSettings?.[key]?.[valInd] ?? null])) || [];
    }
    // device.commTech === "Sigfox"
    return Object.fromEntries(jsonkeys.map( key => [key, device.newSettings?.[key] ?? null]));
  }
);

export const selectDeviceIndexById = createSelector(
  [selectAllDevices, (_, deviceId) => deviceId],
  (devices, deviceId) => devices.findIndex( dev => dev.id === deviceId)
);

export const { 
  deviceAdded, 
  devicesAdded, 
  setDeviceSettings,
  //settingsToBeSentReseted,
  settingsSentTimeStampSet,
  showAdvancedSet,
  newSettingsRestored,
  phonenumbersSet,
  devicesPhonenumbersSet,
  devicesUseStatusSet,
  deviceNameSet,
  deviceUTCzoneOffsetSet,
  privilegesSet,
  dataRangeSet,
  deviceSettingsRefreshed,
} = deviceSlice.actions;

export default deviceSlice.reducer;