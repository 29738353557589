import { useDispatch, useSelector } from 'react-redux';

import { currentPageSet,} from '../data/uiSlice.js';
import ProjectSelect from '../components/Locations/ProjectSelect.js';
import { LocationTable } from '../components/Locations/LocationTable.js';

import {
  Box,
  Stack,
} from '@mui/material';

// Location page
const SubPageLocations = ({selectSubPage}) => {
  useDispatch()(currentPageSet("LOCATIONS"));

  const locationsDataIsLoaded = useSelector(state => state.locations.dataIsLoaded);
  // if(!locationsDataIsLoaded){
  //   dispatch(locationsInit({locations: locationItems, projects, locationTypes}));
  // }

  if(!locationsDataIsLoaded){
    return false
  }

  // LocationAddDialog and AlarmListDialog need the context of LocationTable but they are 
  // rendered outside of it. Thus using Portal. 
  // Cf. LocationAddDialog.js and AlarmListDialog.js.
  return (
    <>
      <Stack sx={{alignItems: "center", justifyContent: "center" }} direction="row" spacing={2}  >
        <ProjectSelect />
        <Box id="alarm-list-dialog" />
        <Box id="location-add-dialog" />
      </Stack>
      <LocationTable selectSubPage={selectSubPage} />
    </>
  )
}
export default SubPageLocations;