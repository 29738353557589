import axios from 'axios'

const fetchDeviceInfoAxios = async (uuid) => {
  const URL = '/api/getDeviceInfo';
  const response = await axios({
    method: 'post',
    url: URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    data: JSON.stringify({ deviceUUID: uuid })
  });
  return response;
}

const options = (uuid = "") => {
  return ({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application'
      },
      body: JSON.stringify({ deviceUUID: uuid })
  });
};

const checkToken = async (token) => {
  const URL = '/api/checkToken';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      token,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const deleteToken = async (token) => {
  const URL = '/api/deleteToken';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      token,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const getDevicesByPhonenumber = async (phonenumberId) => {
  if(phonenumberId == null){
    return null;
  }
  const URL = '/api/getDevicesByPhonenumber';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      phonenumberId,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

// const getDeviceTypes = async (deviceTypeIds) => {
//   if(deviceTypeIds == null){
//     throw new Error(`getDeviceTypes: deviceTypeIds is ${deviceTypeIds}`);
//   }
//   const URL = '/api/getDeviceTypes';  
//   const altOptions = Object.assign(options(), {
//     body: JSON.stringify({ 
//       deviceTypeIds,
//     })
//   });
//   const response = await fetch(URL, altOptions);
//   return response.json();
// };

const getProjectsAndLocations = async (phonenumberId) => {
  if(phonenumberId == null){
    return null;
  }
  const URL = '/api/getProjectsAndLocations';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      phonenumberId,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const getDeviceByUUID = async (uuid) => {
  const URL = '/api/getDeviceByUUID';  
  const response = await fetch(URL, options(uuid));
  return response.json();
};

const checkURLcode = async (urlCode) => {
  const URL = '/api/checkURLcode';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      urlCode
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const loginWithPin = async (uuid, pin) => {
  const URL = '/api/getLogin/pin';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceUUID: uuid,
      pin
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const checkTelegramHash = async (userData) => {
  const URL = '/api/getLogin/checkTelegramHash';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify(userData)
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchDeviceInfo = async (uuid) => {
  const URL = '/api/getDeviceInfo';  
  const response = await fetch(URL, options(uuid));
  return response.json();
};

const fetchDeviceInfo2 = async (uuid) => {
  const URL = '/api/getDeviceInfo';  
  const response = await fetch(URL, options(uuid));
  return response;
};

const fetchPhonenumbers2 = async (uuid) => {
  const URL = '/api/getPhonenumbers';
  const response = await fetch(URL, options(uuid));
  return response;
};

const fetchPhonenumbers = async (deviceId) => {
  const URL = '/api/getPhonenumbers';
  const response = await fetch(URL, options(deviceId));
  return response.json();
};

const fetchUiDesc = async (deviceId) => {
  const URL = '/api/getUiDesc';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchUiData = async (deviceId, jsonkeys, commTech) => {
  const URL = '/api/getUiData';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      jsonkeys,
      commTech
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchPendingSettings = async (deviceId, settingsId) => {
  const URL = '/api/getPendingSettings';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      settingsId
    })
  });
  const response = await fetch(URL, altOptions)
  return response.json();
};

const addLocation = async ({projectId, parentId, name, type, info}) => {
  const URL = '/api/addLocation';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      projectId, 
      parentId: parentId > 0 ? parentId : null, 
      name, 
      type, 
      info
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const updateLocation = async ({locationId, projectId, parentId, name, type, info}) => {
  const URL = '/api/updateLocation';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      locationId,
      projectId, 
      parentId: parentId, 
      name, 
      type, 
      info
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const dropLocation = async ({locationId}) => {
  const URL = '/api/dropLocation';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      locationId
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const updateDeviceLocation = async ({deviceId, locationId, projectId}) => {
  const URL = '/api/updateDeviceLocation';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceId,
      locationId,
      projectId,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const getDeviceAlerts = async ({deviceIds}) => {
  const URL = '/api/getDeviceAlerts';  
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceIds
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const sendNewSettings = async (deviceId, settingsId, settings, commTech) => {
  const URL = '/api/sendNewSettings';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      settingsId,
      settings,
      commTech,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateUnitSystem = async ({userPhoneId, unitSystem}) => {
  const URL = '/api/updateUnitSystem';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      userPhoneId,
      unitSystem,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateHourCycle = async ({userPhoneId, hourCycle}) => {
  if(userPhoneId == null || hourCycle == null){
    throw new ReferenceError(`updateHourCycle: userPhoneId ${userPhoneId}, hourCycle ${hourCycle}`);
  }
  const URL = '/api/updateHourCycle';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      userPhoneId,
      hourCycle,
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};


async function checkPhonenumbers(phonenumbers) {
  const URL = '/api/checkPhonenumbers';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      phonenumbers
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
}

async function sendPhonenumber({ deviceId, phonenumber, country, userType, unitSystem, language, hourCycle }) {
  const URL = '/api/sendPhonenumber';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceId,
      phonenumber,
      country, 
      userType,
      unitSystem, 
      language, 
      hourCycle
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
}

async function updateSuperadmin({deviceId, phonenumber, country, language, unitSystem, hourCycle}) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/updateSuperadmin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId,
      phonenumber,
      country,
      language,
      unitSystem,
      hourCycle
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

// Set superadmin's phone number (and other settings) for multiple devices.
// PARAM: *:s are obligatory {
// devicesId(*), // array of ids
// pinCode(*),   // six digits long str
// superadminPhonenumber(*), // {number (str), country, userType, alarmCall}
// restPhonenumbers, // array of {number, country}
// utcZoneOffset // int
// }]
async function activateDevices({deviceIds, pinCode, superuserPhonenumber, restPhonenumbers, newDeviceNames, utcZoneOffset}) {
  const URL = '/api/activateDevices';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceIds,
      pinCode,
      superuserPhonenumber,
      restPhonenumbers,
      newDeviceNames,
      utcZoneOffset
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
}

async function updatePhonenumbers({deviceId, phonenumbers}){
  console.log("updatePhonenumbers",deviceId, phonenumbers)
  if(deviceId == null || phonenumbers == null || phonenumbers?.length === 0){
    console.log("parameters unvalid");
    return {error: "parameters unvalid"}
  }
  const URL = '/api/updatePhonenumbers';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceId,
      phonenumbers
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
}

async function dropPhonenumber({deviceId, phonenumberId}) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/dropPhonenumber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId,
      phonenumberId
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

async function dropAllPhonenumbers(deviceId) {
  // LISÄÄ altOPTIONS tähän, ks yllä
  await fetch('/api/dropAllPhonenumbers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application'
    },
    body: JSON.stringify({
      deviceId
    })
  })
  .then(res => {
    if (!res.ok) { // error coming back from server
      throw Error('could not update the data for that resource');
    } 
    return res.json();
  })
  .then(data => {
    //setIsLoading(false);
  })
  .catch(err => {
    // auto catches network / connection error
    //setIsLoading(false);
    //setError(err.message);
  })
}

const sendNewDeviceName = async ({deviceId, newName}) => {
  const URL = '/api/sendNewDeviceName';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      newName
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateDevicesUTCzoneOffset = async ({deviceIds, newUTCzoneOffset}) => {
  const URL = '/api/updateDevicesUTCzoneOffset';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({
      deviceIds,
      newUTCzoneOffset
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateLanguage = async ({userPhoneId, language}) => {
  const URL = '/api/updateLanguage';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      language
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updateTermsConfirm = async ({userPhoneId, value}) => {
  const URL = '/api/updateTermsConfirm';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      value
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updatePrivacyPolicyConfirm = async ({userPhoneId, value}) => {
  const URL = '/api/updatePrivacyPolicyConfirm';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      userPhoneId,
      value
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const updatePin = async ({deviceId, newPin}) => {
  const URL = '/api/sendNewPin';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      deviceId,
      newPin
    })
  });
  const response = await fetch(URL, altOptions);
  return response;
};

const fetchOnomondoData = async (UUIDs) => {
  const URL = '/api/onomondo';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      UUIDs
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchInfluxData = async ({bucket, idFieldName, deviceType, deviceUUID, fields}, timeRange, unitSystem, language, hourCycle, offset) => {
  const URL = '/api/getInfluxData';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      idFieldName,
      deviceType,
      deviceUUID,
      fields,
      timeRange,
      unitSystem,
      language,
      hourCycle,
      offset
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchKeyValues = async ({bucket, idFieldName, deviceType, deviceUUID, fields, commTech}) => {
  const URL = '/api/getKeyValues';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      idFieldName,
      deviceType,
      deviceUUID,
      fields,
      commTech,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};

const fetchActivationDates = async ({bucket, deviceType, deviceUUIDs}) => {
  const URL = '/api/getActivationDates';
  const altOptions = Object.assign(options(), {
    body: JSON.stringify({ 
      bucket,
      deviceType,
      deviceUUIDs,
    })
  });
  const response = await fetch(URL, altOptions);
  return response.json();
};



export {
  deleteToken,
  checkToken,
  checkURLcode,
  loginWithPin,
  getDeviceByUUID,
  checkTelegramHash,
  getDevicesByPhonenumber,
  //getDeviceTypes,
  getProjectsAndLocations,
  fetchDeviceInfo,
  fetchDeviceInfo2,
  fetchDeviceInfoAxios,
  fetchPhonenumbers,
  fetchPhonenumbers2,
  fetchUiDesc,
  fetchUiData,
  fetchPendingSettings,
  checkPhonenumbers,
  updatePhonenumbers,
  sendPhonenumber,
  addLocation,
  updateLocation,
  dropLocation,
  updateDeviceLocation,
  getDeviceAlerts,
  dropPhonenumber,
  dropAllPhonenumbers,
  updateLanguage,
  updateSuperadmin,
  activateDevices,
  updateTermsConfirm,
  updatePrivacyPolicyConfirm,
  sendNewSettings,
  sendNewDeviceName,
  updateDevicesUTCzoneOffset,
  updateUnitSystem,
  updateHourCycle,
  updatePin,
  fetchOnomondoData,
  fetchInfluxData,
  fetchKeyValues,
  fetchActivationDates
};