import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';

import { unit } from '../utility/influxChartConfig.mjs';
import logger from '../utility/logger.mjs';
import { round } from '../utility/function.mjs';

import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectCurrentData,
  selectComponentValues,
  selectCurrentNewSettings,
  selectCurrentComponentDesc,
  selectCurrentDeviceIndex,
  selectPrivileges,
} from '../data/devicesSlice.js';
import { selectUnitSystem } from "../data/uiSlice.js";

import { 
  Box,
  Input as MuiInput,
  IconButton as MuiIconButton,
  Slider as MuiSlider,
  styled,
} from '@mui/material';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';


const Input = styled(MuiInput)`
  width: 3.3em;
`;

const IconButton = styled(MuiIconButton)`
  padding: 3px;
`;

// relative max value cannot be less/more than absolute min/max: use miminum of % values
const calculateMinMaxValues = (rangerMinParam,rangerMaxParam,absMinParam,absMaxParam,referencevalue,isInverted) => {
  const rangerMin = rangerMinParam ?? 0;
  const rangerMax = rangerMaxParam ?? 0;
  if(absMinParam == null || absMaxParam == null || referencevalue == null){
    return [rangerMin, rangerMax];
  }
  const absMin = isInverted ? absMaxParam : absMinParam; 
  const absMax = isInverted ? absMinParam : absMaxParam; 
  const absBasedMin = referencevalue !== 0 
    ? round(100 * Math.abs(referencevalue - absMin) / referencevalue, 1) 
    : rangerMin;
  const absBasedMax = referencevalue !== 0 
    ? round(100 * Math.abs(absMax - referencevalue) / referencevalue, 1) 
    : rangerMax;
  return ([
    Math.min(rangerMin, absBasedMin), 
    Math.min(rangerMax, absBasedMax) 
  ]);
}

function PureAlarmLimitRelativeSlider({ t, block }) {
  const INPUT_BUTTON_SIZE = "large";  // "small" | "medium" | "large"
  const NO_VALUE = "--";

  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const referencevalue = useSelector(selectCurrentData)?.referenceValues?.[data?.category];
  const isInverted = desc.INVERTED ?? false;
  const [minValue, maxValue] = calculateMinMaxValues(
    desc?.MIN_VAL,
    desc?.MAX_VAL,
    data.sensorRangeMin,
    data.sensorRangeMax,
    referencevalue,
    isInverted
    );
  const width = desc?.SIZE === "half" ? "43%" : "100%";
  const position = desc?.POSITION;
  const jsonkey =  data.jsonkeys[0];
  const valInd = data.JSONValInd;
  const value = useParamSelector(selectComponentValues, componentId)[0];
  const [inputValue, pureSetInputValue] = useState(value);
  const [sliderValue, setSliderValue] = useState(formatValue(value));
  const unitSystem = useSelector(selectUnitSystem);
  const symbol = unit[unitSystem][data?.category].symbol ?? "";

  // TODO korjaa purkkaviritelmä yleiseksi
  const absoluteLimit = () => {
    if(referencevalue == null){
      return `( -- ${symbol} )`
    }
    let absoluteValue = "--"; 
    if(checkValue(value) === NO_VALUE) {
      absoluteValue = NO_VALUE;
    } else if(jsonkey === "sAmad"){
      absoluteValue = round(referencevalue - (referencevalue * (value / 100.0)),2);
    } else if(jsonkey === "sAmao"){
      absoluteValue = round(referencevalue + (referencevalue * (value / 100.0)),2);
    } else {
      return false
    }
    if(absoluteValue != NO_VALUE){
      absoluteValue = unit[unitSystem][data.category].conv(absoluteValue);
    }
    
    return (
        `( ${absoluteValue} ${symbol} )`
    )
  }

  const checkValue = (value) => {
    if(value < minValue || value > maxValue) {
      return NO_VALUE;
    }
    if(isInverted){
      return -1 * value;
    }
    return value
  }

  // if slider is inverted sliderValue must be separated from inputValue and inverted
  function formatValue(value) {
    return isInverted ? maxValue - value : value;
  }
  
  const setInputValue = (value) => {
    pureSetInputValue(formatValue(value));
    setSliderValue(value);
  }

  const setSetting = (value) => {
    dispatch(currentSettingsSet({
      values: [formatValue(value)],
      jsonkeys: data.jsonkeys,
      valInd
    }));
    setInputValue(value);
  }

  const handleBlur = () => {
    if (inputValue < minValue) {
      setInputValue(minValue);
    } else if (inputValue > maxValue) {
      setInputValue(maxValue);
    }
  };

  const handleInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(typeof numberValue === 'number' && numberValue >= minValue && numberValue <= maxValue){
      setSetting(numberValue);
    }
  };

  const onChangeFunction = (event) => {
    setInputValue(event.target.value);
  };

  const onChangeCommittedFunction = ( event, value ) => {
    setSetting(value);
  };

  const onAddClick = (e) => {
    if (checkValue(value) === NO_VALUE){
      setSetting(maxValue/2);
    }
    else if (value < maxValue) {
      setSetting(formatValue(value + 1));
    }
  }

  const onRemoveClick = (e) => {
    if (checkValue(value) === NO_VALUE){
      setSetting(maxValue/2)
    }
    else if (value > minValue) {
      setSetting(formatValue(value - 1));
    }
  }
  
  return (
    <Box 
      sx={{ 
        m: "0 0.5em 1em", 
        width: `${width}`, 
        float: `${position}`, 
      }}
      style={{textAlign: "center"}}
    >
      <MuiSlider
        disabled={disabled}
        key={`slider--${data.category}-${componentId}`}
        track={ isInverted ? "inverted" : "normal"}
        value={sliderValue} 
        aria-labelledby={ isInverted ? "track-inverted-slider" : "track-slider" }
        step={desc.STEPSIZE}
        min={minValue}
        max={maxValue}
        onChangeCommitted={onChangeCommittedFunction}
        onChange={onChangeFunction}
      />
      { t("label-" + jsonkey) + ": " } 
      <span style={{whiteSpace: 'nowrap'}}>
        <IconButton 
          aria-label="remove" 
          onClick={ isInverted ? onAddClick : onRemoveClick } 
          disabled={disabled || inputValue <= minValue}
        >
          <KeyboardArrowLeftOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
        </IconButton>
        <Input
          disabled={disabled}
          value={checkValue(inputValue)}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: desc.STEPSIZE,
            min: minValue,
            max: maxValue,
            inputmode: 'numeric',
            pattern: '[0-9]*',
            'aria-labelledby': 'input-slider',
          }}
        />
        {<span style={{marginLeft:'-23px', marginTop: '-40px'}}> % </span>}
        <IconButton 
          aria-label="add" 
          onClick={ isInverted ? onRemoveClick : onAddClick } 
          disabled={disabled || inputValue >= maxValue}
        >
          <KeyboardArrowRightOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
        </IconButton>
        <br />
        {absoluteLimit()}
      </span>
    </Box>
  );
}
export const AlarmLimitRelativeSlider = withTranslation()(PureAlarmLimitRelativeSlider);
