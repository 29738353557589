import {useDispatch, useSelector} from 'react-redux';
import {withTranslation} from "react-i18next";

import { currentPageSet } from '../data/uiSlice.js';
import {selectCurrentActivationTime} from "../data/devicesSlice";

import { InfluxCharts } from "../components/InfluxChart.js";
import DeviceHeader from '../components/Devices/DeviceHeader.js';
import {AlertWarning} from "../components/Alert";

function SubPageData({t}) {
  useDispatch()(currentPageSet("DATA"));
  const activationTime = useSelector(selectCurrentActivationTime);

  if(activationTime == null){
    return (
      <>
        <DeviceHeader />
        <AlertWarning
          text={t("device-not-activated-warning")}
          title={false}
        />
      </>
    );
  }

  return (
    <>
      <DeviceHeader />
      <InfluxCharts />
    </>
  );
}

export default withTranslation()(SubPageData)