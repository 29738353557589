import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

import logger from "../../utility/logger.mjs";
import useParamSelector from "../../data/useParamSelector.js";
import { locationSortFunc } from "../../utility/locationConfig.mjs";
import { updateDeviceLocation } from "../../server/serverOperation.js";
import { findById } from "../../utility/function.mjs";
import { deviceMoved, selectAllLocations, selectCurrentProjectLocations, selectLocationPrettyPathById } from "../../data/locationsSlice.js";
import { LocationTypeIcon } from "../Icons/LocationTypeIcon.js";

import InputIcon from '@mui/icons-material/Input';
import CancelIcon from '@mui/icons-material/Cancel';
import EditButton from '@mui/icons-material/Edit';

import { 
  Avatar,
  Box,
  FormControl, 
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  MenuItem, 
  Select, 
  Stack,
  TextField,
} from "@mui/material"

import { TOP_LOCATION_IND } from "./locationTableSettings.js";

// Filter sublocations that are under given seeds
// PARAMS: 
// seeds: array of location ids
// locations: array of location to be filtered
// Returns an array of location ids.
const filterLocationTree = (seeds, locations) => {
  if(seeds.length === 0){
    return locations;
  }
  let newSeeds = [];
  const filteredLocations = locations.filter( l => {
    if(seeds.includes(l.id)){
      newSeeds.push(...l.children);
      return false;
    }
    return true;
  })
  return filterLocationTree(newSeeds,filteredLocations);
}

const LocationMenuItem = ({location}) => {
  const path = useParamSelector(selectLocationPrettyPathById, location?.id);
  return (
    <Stack direction="row" spacing={1} sx={{alignItems: "center",}}>
      <LocationTypeIcon type={location.type} tooltipDisabled/>
      <ListItemText primary={location.name} secondary={path} />
    </Stack>
  )
}

const PureRawLocationSelect = ({t, value, onChange, disabled, valueOnly, filterSeeds=[], deviceVariant, sx }) => {
  const allLocations = useSelector(selectAllLocations);
  const projectLocations = useSelector(selectCurrentProjectLocations)
  const shownLocations = filterLocationTree(filterSeeds, projectLocations).sort(locationSortFunc);
  const topLocation = deviceVariant ? {type: "no-location", name: t("no-location")} : {type: "top", name: t("top")};

  if(valueOnly){
    return(
      <LocationMenuItem location={allLocations.find( location => location.id === value) ?? topLocation } />
    )
  }

  return (
    <FormControl
      sx={{width:"100%", ...sx}}
      size="small"
      variant="standard"
      disabled={disabled}
    >
      <Select
        onChange={onChange}
        value={value ?? TOP_LOCATION_IND}
      >
        <MenuItem value={TOP_LOCATION_IND}>
          <LocationMenuItem location={topLocation} />
        </MenuItem>
        {shownLocations
          .map( location =>
            <MenuItem value={location.id}>
              <LocationMenuItem location={location} />
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}
const RawLocationSelect = withTranslation()(PureRawLocationSelect);

const PureSmartLocationSelect = ({
      t,
      deviceId, 
      locationId,
      editDisabled, 
      fieldOnly, 
      hideNoLocation,
      hidePath,
      onChange:onChangeProp,
      filterSeeds=[],
      variant="standard",
      deviceVariant,
      sx,
    }) => {
  const dispatch = useDispatch();
  const projectId = useSelector(state => state.ui.currentProjectId);
  const locations = filterLocationTree(filterSeeds, useSelector(selectCurrentProjectLocations)).sort(locationSortFunc);
  const currentLocation = deviceId != null
    ? locations?.find( l => l.devices.includes(deviceId))
    : findById(locations,locationId);
  const initLocationId = currentLocation?.id ?? -1;
  const [parentId, setParentId] = useState(initLocationId)
  const [showEdit, setShowEdit] = useState(!!fieldOnly);

  const { mutate:mutateDeviceLocation, isLoading:mutateDeviceLocationisLoading } = useMutation(
    updateDeviceLocation, // parameter {deviceId, locationId, projectId}
    {
      onSuccess: async (data) => {
        dispatch(deviceMoved({
          projectId,
          locationId: parentId, 
          deviceId
        }));
        setShowEdit(false);
      },
      onError: (error) => {
        toast.error(error)
        logger.error(error);
      }
    }
  );

  if(deviceId != null && locationId != null){
    logger.error("LocationSelect: cannot have both deviceId and locationId");
    return false
  }
  if(deviceId == null && locationId == null){
    logger.error("LocationSelect: no deviceId nor locationId given");
    return false
  }

  const handleCancleClick = () => {
    setShowEdit(false);
    setParentId(initLocationId);
  }

  const handleOkClick = () => {
    mutateDeviceLocation({deviceId, locationId:parentId, projectId});
  }
  
  if(!showEdit){
    return (
      <Stack direction="row">
        <RawLocationSelect 
          value={parentId} 
          disabled
          deviceVariant
          />
        { editDisabled 
            ? false
            : <IconButton onClick={() => setShowEdit(true)} >
                <EditButton />
              </IconButton> 
          }
      </Stack>
    )
  }
  // showEdit
  return (
    <Stack direction="row">
      <RawLocationSelect 
        value={parentId}
        onChange={e => setParentId(e.target.value)}
        deviceVariant
      />
      <Box>
        <IconButton onClick={handleOkClick} color="success" >
          <InputIcon />
        </IconButton>
        <IconButton>
          <CancelIcon onClick={handleCancleClick} color="error"/>
        </IconButton>
      </Box>
    </Stack>
  )
}
const SmartLocationSelect = withTranslation()(PureSmartLocationSelect);

export {
  RawLocationSelect,
  SmartLocationSelect,
};
