import { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { 
  useSelector, 
  useDispatch,
} from "react-redux";
import { useQuery, useMutation } from "react-query";
import { toast } from 'react-toastify';

import {
  powerOver,
  userTypes,
} from '../../utility/userConfig.mjs'

import { 
  dropPhonenumber,
} from "../../server/serverOperation.js";
import { 
  selectCurrentDeviceId, 
  selectUserPhoneId,
} from '../../data/uiSlice.js';
import { 
  selectCurrentPhonenumbers, 
  phonenumbersSet,
  selectPrivileges,
} from '../../data/devicesSlice.js';

import UserTypeIcon from "../UserTypeIcon.js";
import { AlertError } from "../Alert.js";
import useConfirm from "../ConfirmDialog.js";
import logger from "../../utility/logger.mjs";
import logout from "../../utility/logout.js";

import { 
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, 
  Select,
  TextField
} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import { 
  USER_COUNT_MAX, 
  ALARM_CALL_COUNT_MAX 
} from "../../utility/userConfig.mjs";

const PurePhonenumberTable = ({t, phonenumbers:numberProp, setPhonenumbers, editDisabled, showSelfDelete, sx}) => {
  const deviceId = useSelector(selectCurrentDeviceId);
  const savedPhonenumbers = useSelector(selectCurrentPhonenumbers);
  const phonenumbers = numberProp ?? savedPhonenumbers;
  const privileges = useSelector(selectPrivileges);
  const userPhoneId = useSelector(selectUserPhoneId);
  const [numberCount, setNumberCount] = useState(0);
  const confirm = useConfirm();

  useEffect(() => {
    setNumberCount(phonenumbers.filter( n => !n.deleted).length);
  },[phonenumbers]);

  const handleUserIconChange = (userType, i) => {
    // no super user selected
    if(userType !== "superadmin" && phonenumbers[i].userType === "superadmin") {
      toast.error(t("error-no-superadmin-selected"), {
        toastId: "error-no-superadmin-selected"
      });
    }
    // superuser's phone numner is not confirmed
    if(userType === "superadmin" && !phonenumbers[i].telegramId) {
      toast.error(t("error-no-superadmin-selected"), {
        toastId: "error-no-superadmin-selected"
      });
    }
    // user losing superadmin privileges because they are given to someone other
    if(userType === "superadmin" && phonenumbers.find( n => n.id === userPhoneId).userType === "superadmin"){
      toast.warn(t("warning-losing-admin-privileges"), {
        toastId: "user-is-downgrading-their-privileges"
      });
    }
    // user is downgrading their own privileges
    if(phonenumbers[i].id === userPhoneId && (userTypes.indexOf(privileges) < userTypes.indexOf(userType))){
      toast.warn(t("warning-losing-admin-privileges"), {
        toastId: "user-is-downgrading-their-privileges"
      });
    }
    const oldSuperadminIndex = phonenumbers.findIndex( n => n.userType === "superadmin");
    // there can be only one superadmin
    if(userType === "superadmin" && oldSuperadminIndex >= 0) {
      setPhonenumbers( prev => {
        return [...prev.slice(0,oldSuperadminIndex), { ...prev[oldSuperadminIndex], userType: "admin" }, ...prev.slice(oldSuperadminIndex + 1)]
      });

    }
    setPhonenumbers( prev => {
      return [...prev.slice(0,i), { ...prev[i], userType }, ...prev.slice(i + 1)]
    });
  }

  const UserIcon = ({i}) => {
    const disabled = editDisabled || phonenumbers[i].deleted || !powerOver(privileges, phonenumbers[i].userType)
    return (
        <TextField
          sx={{ 
            boxShadow: 'none', 
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            width: "3.5em",
            }}
          inputProps={{ IconComponent: () => null }}
          size="small"
          select
          onChange={ (e) => handleUserIconChange(e.target.value, i)}
          value={phonenumbers[i].userType}
          disabled={disabled}
        >
          {userTypes
            .filter( type => disabled || powerOver(privileges, type))
            .map( type =>
              <MenuItem key={type} value={type}>
                <UserTypeIcon userType={type} notActive={phonenumbers[i].deleted} />
              </MenuItem>
            )
          }
        </TextField>
    )
  }

  const PhoneIconButton = ({i}) => {
    const alarmCallCount = phonenumbers.filter( n => n.alarmCall).length;
    const disabled = editDisabled || ( !phonenumbers[i].alarmCall && alarmCallCount >= ALARM_CALL_COUNT_MAX );
    return (
      <IconButton onClick={ () => onPhoneClick(i) } disabled={disabled} >
        { phonenumbers[i].alarmCall 
            ? <PhoneEnabledIcon sx={{color: "green"}} /> 
            : <PhoneDisabledIcon style={{color: "grey"}}/>}
      </IconButton>
    )
  }

  const onPhoneClick = (i) => {
    if(phonenumbers[i].alarmCall){ // alarmCall ON --> switch OFF
      setPhonenumbers( prev =>{
          return [...prev.slice(0,i), { ...prev[i], alarmCall: 0 }, ...prev.slice(i + 1)]//.toSorted(phonenumberSortFunc)
        }
      );
    }
    else { // alarmCall OFF --> switch ON
      setPhonenumbers(prev => {
          return [...prev.slice(0,i), { ...prev[i], alarmCall: 1 }, ...prev.slice(i + 1)]//.toSorted(phonenumberSortFunc)
        }
      );
    }
  }

  const Phonenumber = ({i}) => {
    const ownNumberStyle = userPhoneId === phonenumbers[i].id ? { fontWeight: 'bold' } : {};
    const deletedNumberStyle = phonenumbers[i].deleted ? { color: 'grey' } : {};
    return (
      <span style={{...ownNumberStyle, ...deletedNumberStyle}}>
        {phonenumbers[i].phonenumber}
      </span>
    )
  }

  const DeleteButton = ({i}) => {
    const isOwnNumber = phonenumbers[i].id === userPhoneId;
    if(isOwnNumber && showSelfDelete && !["superadmin","admin"].includes(privileges) ){
      return (
        <IconButton onClick={ () => selfDeleteClick(i) } >
         <DeleteForeverOutlinedIcon color="warning" /> 
        </IconButton>
      )
    }
    if(editDisabled){
      return false
    }
    const restoreDisabled = phonenumbers[i].deleted && numberCount >= USER_COUNT_MAX;
    const disabledResroreStyle = {color: 'grey'}
    if(powerOver(privileges, phonenumbers[i].userType) || isOwnNumber){
      return (
        <IconButton 
          onClick={ () => onDeleteSateClick(i) }
          disabled={ restoreDisabled }
          >
          { phonenumbers[i].deleted 
            ? <RestoreFromTrashIcon color="warning" style={ restoreDisabled ? disabledResroreStyle : {}} /> 
            : <DeleteForeverOutlinedIcon color="warning" /> 
          }
        </IconButton>
      )
    }
    return false
  }

  const onDeleteSateClick = (i) => {
    if(!phonenumbers[i].deleted){
      if(phonenumbers[i].id === userPhoneId){
        toast.warn(t("warning-deleting-oneself"), {
          toastId: "user-is-deleting-theyself"
        });
      }
      if(phonenumbers[i].userType === "superadmin") {
        toast.error(t("error-no-superadmin-selected"), {
          toastId: "error-no-superadmin-selected"
        });
      }
    }
    setPhonenumbers( prev => [...prev.slice(0,i), {...prev[i], deleted:!prev[i].deleted, alarmCall: false}, ...prev.slice(i + 1)] );
  }

  const selfDeleteClick = async (i) => {
    const confirmationOk = await confirm({
      title: "warning",
      children: <AlertError text={"warning-deleting-oneself"} title={"warning-deleting-oneself-title"} />,
      okButtonText: "ok",
      cancelButtonText: "cancel"
    });
    if(confirmationOk){
      // log out after deleting user's own number
      mutateDropPhonenumber({deviceId, phonenumberId:userPhoneId});
      return;
    }
  }

  const { mutate:mutateDropPhonenumber, isLoading:updatePhonenumbersIsLoading } = useMutation(
    dropPhonenumber, // parameter {deviceId, phonenumberId}
    {
      onSuccess: () => {
        logout();
      },
      onError: (error) => {
        logger.error("sendPhonenumber", error);
      }
    }
  );

  const tableRowBackground = (number) => {
    let colorTag;
    if(number.deleted){
      colorTag = 'deleted';
    }
    // number is not checked
    else if(!number.checked && !editDisabled){
      colorTag = 'notCheked';
    }
    // number not connected to telegram bot
    else if(!number.telegramId){ 
      colorTag = 'notValidated';
    }
    // everything ok!
    else {
      colorTag = 'ok'
    }
    return `phonenumberTableRowBackground.${colorTag}`
        // user's number
    // if(number.id === userPhoneId){
    //   if(number.deleted){
    //     return {background: "#E0EEEE"}
    //   }
    //   return {background: "#F0FFF0"}
    // }
    // number id deleted
    // if(number.deleted){
    //   return {background: "#EEEEEE"}
    // }
    // // number is not checked
    // if(!number.checked && !editDisabled){
    //   return {background: "#FFFACD"}
    // }
    // // number not connected to telegram bot
    // if(!number.telegramId){ 
    //   return {background: "#FFE4E1"}
    // }
    // // everything ok!
    // return {background: "#F0FFF0"}
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ width: "100%", marginTop: 2, marginBottom: 2, ...sx}}>
        <Table sx={{ width: "100%", display: "table", tableLayout: "fixed"  }} size="small" aria-label="phonenumber list">
          <TableBody>
            {phonenumbers
              .map( (number, i) => (
              <TableRow
                key={number.phonenumber}
                sx={{ '&:last-child td, &:last-child th': { border: 0, }, backgroundColor: tableRowBackground(number)}}//...tableRowBackground(number) }}
              >
                <TableCell component="th" scope="row" sx={{ width: "2.2em", paddingLeft: "0em", paddingRight: "5px"}}>
                  <UserIcon i={i} />
                </TableCell>
                <TableCell align="center" sx={{ width: "0.7em", paddingLeft: "0.7em", paddingRight: "2em"}}>
                  <PhoneIconButton i={i} />
                </TableCell>
                <TableCell component="th" scope="row" sx={{  paddingLeft: "1em", paffingRight: "1em"}}>
                  <Phonenumber i={i} />
                </TableCell>
                <TableCell align="center" sx={{ width: "1em", paddingLeft: "0.5em", paddingRight: "3em"}}>
                  <DeleteButton i={i} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const PhonenumberTable = withTranslation()(PurePhonenumberTable);
export default PhonenumberTable;
