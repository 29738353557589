import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import { round } from "../utility/function.mjs";
import useParamSelector from '../data/useParamSelector.js'
import {
  currentSettingsSet,
  selectComponentValues,
  selectCurrentDevice,
  selectCurrentDeviceIndex,
  selectCurrentNewSettings,
  selectCurrentComponentDesc
} from '../data/devicesSlice.js';
import {
  selectUnitSystem,
} from "../data/uiSlice.js";
import { unit } from "../utility/influxChartConfig.mjs";

import {
  Box,
  FormControl,
  FormLabel,
  Input as MuiInput,
  IconButton as MuiIconButton,
  styled,
  TextField as MuiTextField,
} from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const Input = styled(MuiInput)`
  width: 3.6em;
`;

const IconButton = styled(MuiIconButton)`
  padding: 3px;
`;

function PureNumberInput({ t, block }) {
  const INPUT_BUTTON_SIZE = "medium"; // "small" | "medium" | "large"
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  const title = desc?.TITLE;
  const valInd = data?.JSONValInd;
  const jsonkey =  data?.jsonkeys?.flat(1);

  const maxValue = desc?.MAX_VAL ?? data?.minMaxRealValues?.[jsonkey]?.max ?? data?.sensorRangeMax ?? 999;
  const minValue = desc?.MIN_VAL ?? data?.minMaxRealValues?.[jsonkey]?.min ?? data?.sensorRangeMin ?? 0;
  const step = data?.step ?? desc?.STEPSIZE ?? 1;
  const rawCurrentValue = useParamSelector(selectComponentValues, componentId)?.[0] ?? desc?.DEFAULT_VALUE ?? 0;
  // Settings value should be a multiple of step + minValue. If not, round it down.
  const valueOffSet = (rawCurrentValue - minValue) % step;
  const currentValue = rawCurrentValue - valueOffSet;

  const usedUnits = desc?.UNITS ?? data?.category ?? "general";
  const unitSystem = useSelector(selectUnitSystem);
  const unitSymbol = unit?.[unitSystem]?.[usedUnits]?.symbol ?? "";

  const convertValue = (v, decimals = 0) => unit[unitSystem][usedUnits].conv(v,decimals);
  const convertValueBack = (v, decimals = 0) => unit[unitSystem][usedUnits].convBack(v,decimals);
  const [inputValue, setInputValue] = useState(convertValue(currentValue))

  const setSettings = (newValue) => {
    dispatch(currentSettingsSet({
      values: [convertValueBack(newValue,2)],
      jsonkeys: [jsonkey],
      valInd
    }));
    setInputValue(round(newValue,2));
  }

  const onAddClick = () => {
    if(inputValue < maxValue){
      setSettings(inputValue + step)
    }
  }

  const onRemoveClick = () => {
    if(inputValue > minValue){
      setSettings(inputValue - step)
    }
  }

  const handleInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(event.target.value.length === 0 || numberValue < minValue){
      setSettings(minValue);
    }
    else if (numberValue > maxValue){
      setSettings(maxValue);
    }
    else {
      setSettings(numberValue);
    }
  };

  return (
    <FormControl
      sx={{
        ml: 2,
        mb: 2,
        textAlign: "left"
      }}>
      <FormLabel>{t(title)}</FormLabel>
      <Box sx={{whiteSpace: 'nowrap', mt: 1}}>
        <IconButton
          aria-label="remove"
          onClick={onRemoveClick}
          disabled={disabled}
        >
          <RemoveCircleOutlineOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
        </IconButton>
        <Input
          disabled={disabled}
          value={inputValue}
          size="small"
          onChange={handleInputChange}
          // onBlur={handleBlur}
          inputProps={{
            step: step,
            min: minValue,
            max: maxValue,
            inputmode: 'numeric',
            pattern: '[0-9]*',
            'aria-labelledby': 'input-range-slider-low',
          }}
        />
        <span style={{marginLeft:'-34px', marginTop: '-40px'}}> {unitSymbol} </span>
        <IconButton
          aria-label="add"
          onClick={onAddClick}
          disabled={disabled}
        >
          <AddCircleOutlineOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
        </IconButton>
      </Box>
    </FormControl>
  )
};
const NumberInput = withTranslation()(PureNumberInput);
export {
  NumberInput
};