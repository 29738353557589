import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import logger from "../../utility/logger.mjs";

import { selectCurrentProjectId } from "../../data/uiSlice";
import { RawDialog } from "../InfoDialog";
import {
  updateLocation
} from '../../server/serverOperation'
import { LocationTypeSelect } from "./LocationTypeSelect";
import { RawLocationSelect } from "./LocationSelect";
import { DataList } from "../DataList/DataList";

import { 
  GridActionsCellItem,
  useGridApiContext 
} from "@mui/x-data-grid-pro";

import { 
  LOCATION_NAME_LENGTH_MAX, 
  LOCATION_INFO_LENGTH_MAX 
} from '../../utility/locationConfig.mjs'
import MoveDownIcon from '@mui/icons-material/MoveDown';

import { TOP_LOCATION_IND } from "./locationTableSettings";
import TextInput from "../DataList/TextInput";
import { Tooltip } from "@mui/material";
import {
  locationUpdated,
  // locationUpdated,
  selectLocationById
} from "../../data/locationsSlice";
import {removeItemFromArray} from "../../utility/function.mjs";

const PureLocationMoveDialog = ({t, locationId, openButtonType, showInMenu, dataGridAction}) =>  {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  const location = useSelector(selectLocationById(locationId))
  const projectId = useSelector(selectCurrentProjectId);

  const [menuOpenState, setMenuOpenState] = useState(false);
  const [newParentLocationId, setNewParentLocationId] = useState(location.parentId ?? TOP_LOCATION_IND);

  const { mutate:mutateLocations, isLoading:mutateLocationsIsLoading } = useMutation(
    updateLocation, // parameter {locationId, projectId, parentId, name, type, info}
    {
      onSuccess: () => {
        const movedLocation = {
          id: locationId,
          projectId,
          parentId:newParentLocationId,
        }
        // PATH
        let newPath = location.path;
        // Update path of the target location.
        if(newParentLocationId > 0){
          newPath = apiRef.current.getRow(newParentLocationId).path.concat([locationId]);
        }
        else{ // the target location is moved to the top
          newPath = [locationId];
        }
        // CHILDREN
        // Remove target location id from its OLD parent's list of children,
        // if NEW parent is not the top.
        if(location.parentId !== null){
          const parentsChildren = apiRef.current.getRow(location.parentId).children;
          const updatedChildren = removeItemFromArray(parentsChildren, locationId);
          apiRef.current.updateRows([{id: location.parentId, children: updatedChildren}]);
        }
        // Add target location id to its NEW parent's list of children,
        // if NEW parent is not the top.
        if(newParentLocationId > 0){
          const parentsChildren = apiRef.current.getRow(newParentLocationId).children;
          const updatedParentsChildren = parentsChildren.concat([locationId]);
          apiRef.current.updateRows([{id: newParentLocationId, children: updatedParentsChildren}]);
        }
        // Update paths of target location's children
        (function updatePathRecursion(childLocationIds){
          childLocationIds.forEach(childLocationId => {
            const newChildPath = newPath.concat([childLocationId]);
            apiRef.current.updateRows([{id: childLocationId, path: newChildPath}]);
            const childLocation = apiRef.current.getRow(childLocationId);
            updatePathRecursion(childLocation.children);
          })
        })(location.children)
        // FOR REDUX SLICE:
        dispatch(locationUpdated(movedLocation));
        // FOR DATA GRID:
        apiRef.current.updateRows([{...movedLocation, path: newPath}]);

        toast.success(t("location-moved-info", {locationName: location.name}), {
          toastId: `location-moved-info-${location.name}`
        });
        setMenuOpenState(false);
      },
      onError: (error) => {
        toast.error(t("location-moved-error", {locationName: location.name}), {
          toastId: "location-moved-error"
        });
        logger.error("moveLocation", error);
      }
    }
  );
  
  const handleMoveClick = (e) => {
      mutateLocations({
        locationId,
        projectId,
        parentId: newParentLocationId,
      });
  }

  // The latter is for top location: see definition of newParentLocationId.
  const okButtonDisabled =
    location.parentId === newParentLocationId
    || (location.parentId == null && newParentLocationId === -1);

  const dialogData = {
    title: "move-location",
    openButtonType,
    openButtonText: "move-location",
    openButtonColor: "success",
    okButtonText: "move",
    okButtonFunc: handleMoveClick,
    okButtonIsLoading: mutateLocationsIsLoading,
    cancelButtonText: "close",
    cancelButtonFunc: () => setMenuOpenState(false),
    cancelButtonIsLoading: mutateLocationsIsLoading,
    hideOpenButton: true,
    okButtonDisabled,
  };

  if(dataGridAction){
    return (
      <>
        <Tooltip title={showInMenu ? null : t('move-location')} >
          <GridActionsCellItem 
            showInMenu={showInMenu}
            label={t('move-location')}
            icon={<MoveDownIcon />}
            onClick={() => setMenuOpenState(true)} 
          />
        </Tooltip>
        <RawDialog
          dialogOpen={menuOpenState}
          data={dialogData}
        >
          <p>{t("location-move-info-text", {locationName: location?.name})}</p>
          <RawLocationSelect
            sx={{width:"100%"}}
            value={newParentLocationId}
            filterSeeds={[locationId]}
            onChange={e => setNewParentLocationId(e.target.value)}
          />
        </RawDialog>
      </>
    )
  }

  return (
    <RawDialog
      openButtonSX={{m: 0}}
      data={dialogData}
    >
      <p>{t("location-move-info-text", {locationName: location.name})}</p>
      <RawLocationSelect
        sx={{width: "100%"}}
        value={newParentLocationId}
        onChange={e => setNewParentLocationId(e.target.value)}
      />
    </RawDialog>
  )
}
const LocationMoveDialog = withTranslation()(PureLocationMoveDialog);
export default LocationMoveDialog;