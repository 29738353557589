import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import { round } from '../utility/function.mjs';
import { unit } from '../utility/influxChartConfig.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectCurrentData,
  selectComponentValues,
  selectCurrentComponentDesc,
} from '../data/devicesSlice.js';
import { selectUnitSystem } from "../data/uiSlice.js";

import { 
  Box,
  Input as MuiInput,
  IconButton as MuiIconButton,
  InputAdornment,
  Slider,
  styled,
  TextField as MuiTextField,
} from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useEffect } from "react";

// style reference value mark on basis of it position
const Ranger = styled(Slider)({
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 30,
    width: 1.5,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: "translateX(-10%)",
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: "translateX(-50%)",
  },
  '& .MuiSlider-markLabel[data-index="2"]': {
    transform: "translateX(-90%)",
  }
});

const Input = styled(MuiInput)`
  width: 4.5em;
`;

const IconButton = styled(MuiIconButton)`
  padding: 3px;
`;

function PureRangeSlider({ t, block }) {
  const INPUT_BUTTON_SIZE = "large"; // "small" | "medium" | "large"
  const NO_VALUE = "--";

  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const latestValue = useSelector(selectCurrentData)?.latestValues?.[data?.category];
  const width = desc?.SIZE === "half" ? "43%" : "90%";
  const position = desc?.POSITION ?? "center";
  const jsonkeys =  data.jsonkeys;
  const valInd = data.JSONValInd;
  const rawValues = useParamSelector(selectComponentValues, componentId);

  const disableOnExtremeValues = !!desc?.disableOnExtremeValues;

  const unitSystem = useSelector(selectUnitSystem);
  const unitSymbol = unit[unitSystem][data?.category].symbol;

  const convertValues = (v, decimals = 0) => {
    if(Array.isArray(v)){
      return v.map( (value,i) => {
        if(i < 2){
          return unit[unitSystem][data?.category].conv(value,decimals);
        }
        return value;
     });
    }
    return unit[unitSystem][data?.category].conv(v,decimals);
  }
  const convertValuesBack = (v, decimals = 0) => {
    if(Array.isArray(v)){
      return v.map( value => unit[unitSystem][data?.category].convBack(value,decimals) );
    }
    return unit[unitSystem][data?.category].convBack(v,decimals);
  }
  const [values, setValues] = useState(convertValues(rawValues.slice(0,2)));
  const descMinVal = desc?.MIN_VAL ? convertValues(desc?.MIN_VAL,0) : null;
  const descMaxVal = desc?.MAX_VAL ? convertValues(desc?.MAX_VAL,0) : null;
  const sensorRangeMin = data?.sensorRangeMin ? convertValues(data?.sensorRangeMin,0) : null;
  const sensorRangeMax = data?.sensorRangeMax ? convertValues(data?.sensorRangeMax,0) : null;
  const lowMinValue   = descMinVal ?? convertValues(data?.minMaxRealValues?.[jsonkeys[0]]?.min,0) ?? sensorRangeMin ?? 0;
  const lowMaxValue   = descMaxVal ?? convertValues(data?.minMaxRealValues?.[jsonkeys[0]]?.max,0) ?? sensorRangeMax ?? 100; 
  const highMinValue  = descMinVal ?? convertValues(data?.minMaxRealValues?.[jsonkeys[1]]?.min,0) ?? sensorRangeMin ?? 0;
  const highMaxValue  = descMaxVal ?? convertValues(data?.minMaxRealValues?.[jsonkeys[1]]?.max,0) ?? sensorRangeMax ?? 100; 
  const [[rangeMin,rangeMax], setRangeMinMax] = useState([lowMinValue,highMaxValue])
  const step = data?.step ?? desc?.STEPSIZE ?? (rangeMin - rangeMax > 20 ? 1 : 0.1);
  const [lowAlertEnabled, setLowAlertEnabled] = useState(rawValues?.[2] ?? true);
  const [highAlertEnabled, setHighAlertEnabled] = useState(rawValues?.[3] ?? true);
  const alarmDisabled = !lowAlertEnabled && !highAlertEnabled
  
  const referenceValueMark = () => {
    if(latestValue == null){
      return [];
    }
    let convertedRefValue = round(convertValues(latestValue),1);
    let formatedRefValue = convertedRefValue;
    if(formatedRefValue > rangeMax){
      formatedRefValue = rangeMax;
    }
    else if(formatedRefValue < rangeMin){
      formatedRefValue = rangeMin;
    }
    // Reference value is styled accordingly of it's index (0...2) in the mark array.
    // In order ot change style choose a different index.
    let i = 1;
    const peripheryDistance = (rangeMax - rangeMin) / 4;
    if(formatedRefValue - rangeMin < peripheryDistance ){
      i = 0;
    }
    else if(rangeMax - formatedRefValue < peripheryDistance ){
      i = 2;
    }
    return Array(3).fill({ value:rangeMin, label:null }).toSpliced(i, 1,
      {
        value: convertedRefValue,
        label: `${t("latest-value")}: ${formatedRefValue} ${unitSymbol}`
      }).concat([{ value: rangeMax, label: null }]);
  };

  const checkValue = (value, type) => {
    if(value == null || isNaN(value)){
      //logger.info("checkValue value is not a number");
      return NO_VALUE;
    }
    if(value < rangeMin || value > rangeMax) {
      //logger.info("checkValue out of range");
      return NO_VALUE;
    }
    if(type === "low" && value > values[1]){
      //logger.info("checkValue low > high",type, value, values[1]);
      return NO_VALUE;
    }
    if(type === "high" && value < values[0]){
      //logger.info("checkValue low < high",type, value, values[0]);
      return NO_VALUE;
    }
    return value
  }

  const setSettings = (newRawValues) => {
    // if a value is not defined, set it to default
    const newValues = [
      typeof newRawValues[0] === 'number' ? newRawValues[0] : lowMinValue,
      typeof newRawValues[1] === 'number' ? newRawValues[1] : highMaxValue,
    ];
    if(disableOnExtremeValues){
      setLowAlertEnabled(newValues[0] > lowMinValue);
      setHighAlertEnabled(newValues[1] < highMaxValue);
    }
    dispatch(currentSettingsSet({
      values: [
        ...convertValuesBack(newValues.slice(0,2),2),  // values
        Number(newValues[0] > lowMinValue),               // lowIsEnabled if low is set to min
        Number(newValues[1] < highMaxValue),              // highIsEnabled if high is set to max
      ],
      jsonkeys,
      valInd,
    }));
    setValues(round(newValues,2));
  }

  // const handleBlur = () => {
  //   if (inputValue < minValue) {
  //     setInputValue(minValue);
  //   } else if (inputValue > maxValue) {
  //     setInputValue(maxValue);
  //   }
  // };

  const handleLowInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(typeof numberValue === 'number' && numberValue >= lowMinValue && numberValue <= lowMaxValue){
      const newValues = [numberValue, Math.max(numberValue, values[1])];
      setSettings(newValues);
    }
  };

  const handleHighInputChange = (event) => {
    const numberValue = Number(event.target.value);
    if(typeof numberValue === 'number' && numberValue >= highMinValue && numberValue <= highMaxValue){
      const newValues = [Math.min(values[0], numberValue), numberValue];
      setSettings(newValues);
    }
  };

  const onSliderChange = (_, newValues, activeThumb) => {
    if(disableOnExtremeValues){
      setLowAlertEnabled(newValues[0] > lowMinValue);
      setHighAlertEnabled(newValues[1] < highMaxValue);
    }

    if (!Array.isArray(newValues)) {
      return;
    }

    if (activeThumb === 0) { // left circle
      setValues([Math.min(lowMaxValue, newValues[0], values[1]), values[1]]);
    } else {                // right circle
      setValues([values[0], Math.max(highMinValue, newValues[1], values[0])]);
    }
  };

  const onChangeCommittedFunction = (_, newValues, activeThumb) => {
    if (activeThumb === 0) {
      setSettings([Math.min(lowMaxValue, newValues[0]), values[1]]);
    } else {
      setSettings([values[0], Math.max(highMinValue, newValues[1])]);
    }
  };

  const onAddClick = (type) => {
    // index for value array:
    const i = type === "low" ? 0 : 1
    const maxValue = type === "low" ? lowMaxValue : highMaxValue;
    // input has no value --> set the value to min or max value
    if (checkValue(values[i]) === NO_VALUE){
      setSettings(values.toSpliced(i, 1, type === "low" ? lowMinValue : highMaxValue));
    }
    // input has value:
    else if (values[i] < maxValue) {
      // adding low limit, and the value would go over the high limit value --> add both until reach max
      if(type === "low" && values[0] + step > values[1]){
        const newValues = Array(2).fill(Math.min(values[0] + step, maxValue));
        setSettings(newValues)
      }
      else {
        setSettings(values.toSpliced(i, 1, Math.min(values[i] + step, maxValue)));
      }
    }
  }

  const onRemoveClick = (type) => {
    const i = type === "low" ? 0 : 1
    const minValue = type === "low" ? lowMinValue : highMinValue;
    // input has no value --> set the value to min or max value
    if (checkValue(values[i]) === NO_VALUE){
      setSettings(values.toSpliced(i, 1, type === "low" ? lowMinValue : highMaxValue));
    }
    // input has value:
    else if (values[i] > minValue) {
      // adding low limit, and the value would go over the high limit value --> add both until reach max
      if(type === "high" && values[1] - step < values[0]){
        const newValues = Array(2).fill(Math.max(values[1] - step, minValue));
        setSettings(newValues)
      }
      else {
        setSettings(values.toSpliced(i, 1, Math.max(values[i] - step, minValue)));
      }
    }
  }

  const inputUnitSymbol = (type) => {
    const chekedValue = checkValue(values[type === "low" ? 0 : 1], type);
    if(disableOnExtremeValues){
      if((type === "low" && chekedValue === lowMinValue) || (type === "high" && chekedValue === highMaxValue)){
        return null //false
      }
    }
    return <span style={{marginBottom: '3px'}}> {unitSymbol} </span>
  }

  const formatInputValue = (type) => {
    const chekedValue = checkValue(values[type === "low" ? 0 : 1], type);
    if(typeof chekedValue === "number"){
      if(disableOnExtremeValues){
        if((type === "low" && chekedValue === lowMinValue) || (type === "high" && chekedValue === highMaxValue)){
          return t("off")
        }
      }
      if(typeof chekedValue === "number") {
        return chekedValue.toFixed(step < 1 ? 1 : 0)
      }
    }
    return chekedValue;
  }

  const LowInput = () => {
    const sx = {color: alarmDisabled ? 'text.disabled' : false};
    return (
      <Box sx={sx}>
        { t("label-" + jsonkeys[0]) + ": " } 
        <span style={{whiteSpace: 'nowrap'}}>
          <IconButton 
            sx={sx}
            aria-label="remove" 
            onClick={() => onRemoveClick("low")} 
            disabled={disabled || values[0] === lowMinValue}
          >
            <KeyboardArrowLeftOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
          <Input
            sx={sx}
            disabled={disabled}
            value={formatInputValue("low")}
            size="small"
            onChange={handleLowInputChange}
            endAdornment={inputUnitSymbol("low")}
            // onBlur={handleBlur}
            inputProps={{
              step: step,
              min: lowMinValue,
              max: lowMaxValue,
              inputmode: 'numeric',
              pattern: '[0-9]*',
              'aria-labelledby': 'input-range-slider-low',
            }}
          /> 
          <IconButton 
            sx={sx}
            aria-label="add" 
            onClick={() => onAddClick("low")} 
            disabled={disabled || values[0] === lowMaxValue}
          >
            <KeyboardArrowRightOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
        </span>
      </Box>
    )
  }

  const HighInput = () => {
    const sx = {color: alarmDisabled ? 'text.disabled' : false};
    return(
      <Box sx={sx}>
        { t(`label-${jsonkeys[1]}`) + ": " } 
        <span style={{whiteSpace: 'nowrap'}}>
          <IconButton 
            aria-label="remove" 
            onClick={() => onRemoveClick("high")} 
            disabled={disabled || values[1] === highMinValue}
            sx={sx}
          >
            <KeyboardArrowLeftOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
          <Input
            disabled={disabled}
            sx={sx}
            value={formatInputValue("high")}
            size="small"
            onChange={handleHighInputChange}
            // onBlur={handleBlur}
            endAdornment={inputUnitSymbol("high")}
            inputProps={{
              step: step,
              min: highMinValue,
              max: highMaxValue,
              inputmode: 'numeric',
              pattern: '[0-9]*',
              'aria-labelledby': 'input-range-slider-high',
            }}
          /> 
          <IconButton 
            aria-label="add" 
            onClick={() => onAddClick("high")} 
            disabled={disabled || values[1] === highMaxValue}
            sx={sx}
          >
            <KeyboardArrowRightOutlinedIcon fontSize={INPUT_BUTTON_SIZE}/>
          </IconButton>
        </span> 
      </Box>
    )
  }

  return (
    <Box 
      sx={{ 
        m: "0 0 1em",
        p: "0 1em",
        width: `${width}`, 
        float: `${position}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        //bgcolor: !lowAlertEnabled && !highAlertEnabled ? '#cdcdcd' : false,
        borderRadius: 2,
      }}
    >
      <Box sx={{mb:2}}>
        <Ranger
          disabled={disabled}
          sx={{color: alarmDisabled ? 'grey' : false}}
          key={`range-slider--${data?.category}-${componentId}`}
          value={values} 
          aria-labelledby="track-range-slider"
          //getAriaValueText={valuetext}
          //valueLabelDisplay="auto"
          marks={referenceValueMark()}
          step={step}
          min={rangeMin}
          max={rangeMax}
          onChangeCommitted={onChangeCommittedFunction}
          onChange={onSliderChange}
          disableSwap
        />
      </Box>
      <Box 
        sx={{ 
          display: "flex",
          justifyContent: "space-around",
          gap: "10%",
          }}
      >
        <LowInput disabled={disabled || alarmDisabled}/>
        <HighInput disabled={disabled || alarmDisabled}/>
      </Box>
    </Box>
  );
}
export const RangeSlider = withTranslation()(PureRangeSlider);
