import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import logger from '../../utility/logger.mjs';
import {
  updateLocation  
} from '../../server/serverOperation'
import { selectCurrentProjectId } from '../../data/uiSlice';
import {
  locationUpdated,
  //locationUpdated,
  selectLocationById
} from '../../data/locationsSlice';

import { DataList } from '../DataList/DataList';
import { ButtonSmall } from '../Button';
import { RawLocationSelect } from './LocationSelect';
import { LocationTypeSelect } from './LocationTypeSelect';
import useConfirm from '../ConfirmDialog';
import TextInput from '../DataList/TextInput';

import { useGridApiContext } from '@mui/x-data-grid-pro';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  lighten,
} from '@mui/material/';
import Edit from "@mui/icons-material/Edit";

import {
  LOCATION_INFO_LENGTH_MAX,
  LOCATION_NAME_LENGTH_MAX
} from '../../utility/locationConfig.mjs';
import useDetailsEditModeSetter from "../useDetailsEditModeSetter";
import {findById, removeItemFromArray} from "../../utility/function.mjs";

const EditLocationButton = withTranslation()(({t, handleResetClick, editModeEnabled, toggleEditModeEnabled}) => {
  const iconBgColor = editModeEnabled ? (theme) => lighten(theme.palette.success.main, .8) : "";
  const iconColor = editModeEnabled ? "success" : "inherent";
  const handleClick = () => {
    handleResetClick();
    toggleEditModeEnabled()
  }
  return(
      <Tooltip sx={{backgroundColor: iconBgColor}} title={t('edit')} >
        <IconButton  onClick={handleClick} >
          <Edit color={iconColor} />
        </IconButton>
      </Tooltip>
  );
})

// COMPONENT: Detail panel that opens under a location row. 
const PureLocationDetails = ({t, locationId}) => {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch()

  const location = useSelector(selectLocationById(locationId))
  const projectId = useSelector(selectCurrentProjectId);

  const [newLocationName, setNewLocationName] = useState(location.name);
  const [newLocationType, setNewLocationType] = useState(location.type);
  const [newLocationInfo, setNewLocationInfo] = useState(location.info ?? '');
  const [newParentLocationId, setNewParentLocationId] = useState(location.parentId);

  // Enable ok/save button only if something has changed.
  const okButtonDisabled =
      newLocationName === location.name
        && newLocationType === location.type
        && ((newLocationInfo.length === 0 && location?.info == null) || setNewLocationInfo === location?.info)
        && ((newParentLocationId === -1 && location.parentId == null) || (newParentLocationId === location.parentId));

  const handleResetClick = () => {
    setNewLocationName(location.name);
    setNewLocationType(location.type);
    setNewLocationInfo(location.info ?? '');
    setNewParentLocationId(location.parentId);
  }

  const [editModeEnabled, toggleEditModeEnabled] = useDetailsEditModeSetter(handleResetClick)

  const { mutate:mutateLocations, isLoading:mutateLocationsIsLoading } = useMutation(
    updateLocation, // parameter {id, projectId, parentId, name, type, info}
    {
      onSuccess: () => {
        const updatedLocation = {
          id: locationId,
          projectId,
          parentId:newParentLocationId,
          name: newLocationName,
          info: newLocationInfo,
          type: newLocationType,
        }
        let newPath = location.path;
        // The target location is moved.
        if(newParentLocationId !== location.parentId){
          // PATH
          // Update path of the target location.
          if(newParentLocationId > 0){
            newPath = apiRef.current.getRow(newParentLocationId).path.concat([locationId]);
          }
          else{ // the target location is moved to the top
            newPath = [locationId];
          }
          // CHILDREN
          // Remove target location id from its OLD parent's list of children,
          // if NEW parent is not the top.
          if(location.parentId !== null){
            const parentsChildren = apiRef.current.getRow(location.parentId).children;
            const updatedChildren = removeItemFromArray(parentsChildren, locationId);
            apiRef.current.updateRows([{id: location.parentId, children: updatedChildren}]);
          }
          // Add target location id to its NEW parent's list of children,
          // if NEW parent is not the top.
          if(newParentLocationId > 0){
            const parentsChildren = apiRef.current.getRow(newParentLocationId).children;
            const updatedParentsChildren = parentsChildren.concat([locationId]);
            apiRef.current.updateRows([{id: newParentLocationId, children: updatedParentsChildren}]);
          }
          // Update paths of target location's children
          (function updatePathRecursion(childLocationIds){
            childLocationIds.forEach(childLocationId => {
              const newChildPath = newPath.concat([childLocationId]);
              apiRef.current.updateRows([{id: childLocationId, path: newChildPath}]);
              const childLocation = apiRef.current.getRow(childLocationId);
              updatePathRecursion(childLocation.children);
            })
          })(location.children)
        }
        // FOR REDUX SLICE:
        dispatch(locationUpdated(updatedLocation));
        // FOR DATA GRID:
        apiRef.current.updateRows([{...updatedLocation, path: newPath}]);

        toast.success(t("location-updated-info", {locationName: newLocationName}), {
          toastId: `location-updated-${newLocationName}`
        });
      },
      onError: (error) => {
        toast.error(t("location-edit-error"), {
          toastId: "location-edit-error"
        });
        logger.error("updateLocation", error);
      }
    }
  );

  const handleLocationSaveClick = async () => {
    if (newLocationName.length > 0) {
      mutateLocations({
        locationId,
        projectId,
        parentId: newParentLocationId,
        name: newLocationName,
        type: newLocationType,
        info: newLocationInfo,
      });
      toggleEditModeEnabled();
    } else {
      toast.warning(t("please-give-a-name"));
    }
  }

  const isLoading = mutateLocationsIsLoading;

  // List of information about the location.
  // Array of rows with of a header and conents.
  const locationDataForList = [
    [
      t("location-name"),
      <TextInput
        value={newLocationName}
        disabled={isLoading}
        textOnly={!editModeEnabled}
        onChange={e => setNewLocationName(e.target.value)}
        inputProps={{maxLength: LOCATION_NAME_LENGTH_MAX}}
        />
    ],
    [
      t("location-info"),
      <TextInput
        placeholder={t('location-info-placeholder')}
        value={newLocationInfo}
        disabled={isLoading}
        textOnly={!editModeEnabled}
        onChange={e => setNewLocationInfo(e.target.value)}
        inputProps={{maxLength: LOCATION_INFO_LENGTH_MAX}}
      />
    ],
    [
      t("type"),
      <LocationTypeSelect
        sx={{width:"100%"}}
        disabled={isLoading}
        valueOnly={!editModeEnabled}
        type={editModeEnabled ? newLocationType : location.type}
        onChange={e => setNewLocationType(e.target.value)}
      />
    ],
    [
      t("position"), 
      <RawLocationSelect 
        sx={{width:"100%"}}
        disabled={isLoading}
        valueOnly={!editModeEnabled}
        filterSeeds={[location.id]}
        value={editModeEnabled ? newParentLocationId : location.parentId}
        onChange={e => setNewParentLocationId(e.target.value)}
      />
    ]
  ];

  return (
    <Box sx={{width: '350px',}}>
    <Card variant="outlined" >
      <CardContent>
        <Stack 
          direction="row" 
          spacing={0}
          sx={{
            mx:1,
            justifyContent: "space-between",
            alignItems: "center",
            }}
          >
          <Typography gutterBottom variant="h2" component="div">
            {t('location')}
          </Typography>
          <EditLocationButton
              editModeEnabled={editModeEnabled}
              toggleEditModeEnabled={toggleEditModeEnabled}
              handleResetClick={handleResetClick}
          />
      </Stack>
      <DataList 
        data={locationDataForList}
        key={locationId}
      />
      </CardContent>
      { editModeEnabled &&
        <Stack direction="row" sx={{mb: 1, mt: -1, justifyContent: "space-around",}}>
          <ButtonSmall
            sx={{m: 0}}
            text="clear"
            onClick={handleResetClick}
            disabled={isLoading}
            />
          aa
          <ButtonSmall
            sx={{m: 0}}
            text="save"
            onClick={handleLocationSaveClick}
            disabled={okButtonDisabled}
            isLoading={isLoading}
            />
        </Stack>
      }
    </Card>
    </Box>
  )
}
const LocationDetails = withTranslation()(PureLocationDetails);

export default LocationDetails;