import { withTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@mui/material';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import ShortTextIcon from '@mui/icons-material/ShortText';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import TabIcon from '@mui/icons-material/Tab';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';

const IconComponent = {
  "no-location": RemoveIcon,
  "top": VerticalAlignTopIcon,
  "city": HomeWorkOutlinedIcon,
  "address": ShortTextIcon,
  "building": HomeOutlinedIcon,
  "staircase": StairsOutlinedIcon,
  "floor": CalendarViewMonthIcon,
  "apartment": DoorFrontOutlinedIcon,
  "room": TabIcon,
  // "other": CircleOutlinedIcon // default, handled below
}

// Tooltip for the icon telling the name of the type
const TooltipWrapper =  withTranslation()(({t, children, disabled, type}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={`${t('type')}: ${t(type)}`}>{children}</Tooltip>;
})

// An element that translates type names to corresponding  icons. 
// type: typename, sx: styles, fontSize: size of the icon ('small' (def) | 'medium' | 'large'),
// color: color of the icon, tooltipDisabled: disables the tooltip 
export const LocationTypeIcon = ({type, sx, fontSize='small', color='DarkSlateGray', tooltipDisabled}) => {
  if(type == null){
    return false
  }

  const Icon = IconComponent[type] || CircleOutlinedIcon

  return (
    <TooltipWrapper type={type} disabled={tooltipDisabled}>
      <Icon sx={{...sx, color}} fontSize={fontSize} />
    </TooltipWrapper>
  )
}