import { 
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { selectCurrentProjectId } from "./uiSlice";
import { findById, findIndexById } from "../utility/function.mjs";
import { locationSortFunc } from "../utility/locationConfig.mjs";

const initialState = {
  dataIsLoaded: false,
  showAllChildren: false,
  editMode: false,
  project: [],
  location: [],
  locationType: [],
  modifiedLocations: [],
  error: null,
};

const locationTypeSortFunc = (a,b) => {
  if(a.weight < b.weight){
    return -1
  }
  if(b.weight < a.weight){
    return 1
  }
  return 0
}

const getPath = (id, locations) => {
  const path = [];
  (function constructPathRecursion(id) {
    path.push(id);
    const parentId = findById(locations, id).parentId;
    if(parentId == null){
      return path
    }
    return constructPathRecursion(parentId)
  })(id)
  return path.reverse();
}

const updateChildLocationsPaths = (childrenLocations, newPath, locations) => {
  (function updatePathRecursion(childLocationIds){
    childLocationIds.forEach(childLocationId => {
      const childLocation = findById(locations, childLocationId);
      childLocation.path = newPath.concat([childLocationId]);
      updatePathRecursion(childLocation.children);
    })
  })(childrenLocations)
}

export const deviceMoved = createAsyncThunk(
  'locations/deviceMoved',
  ({locationId:targetLocationId, deviceId}, { getState, rejectWithValue }) => {
    const locations = JSON.parse(JSON.stringify(getState().locations.location));
    let targetLocation = {};
    let oldParentLocation = {};
    let modifiedLocations = [];
    const targetI = findIndexById(locations, targetLocationId);
    const deviceI = findIndexById(getState().devices.device, deviceId);
    if( targetLocationId !== -1 && targetI === -1 ){
      return rejectWithValue("locations/movedToLocation: given location id is not valid");
    }
    if((deviceI === -1) ){
      return rejectWithValue("locations/movedToLocation: given device id is not valid");
    }
    const oldParentLocationI = locations.findIndex( l => l.devices.includes(deviceId));
    // If the device had was located somewhere, remove its id from children list of the location
    if(oldParentLocationI !== -1){
      oldParentLocation = locations[oldParentLocationI];
      oldParentLocation.devices.splice(oldParentLocation.devices.indexOf(deviceId),1);
      modifiedLocations.push(oldParentLocation.id);
    }
    // If device is moved to a location, add its id into the locations children list
    if(targetI !== -1){
      targetLocation = locations[targetI];
      targetLocation.devices.push(deviceId);
      modifiedLocations.push(targetLocationId);
    } 
    return {newLocations:locations, newModifiedLocations:modifiedLocations};
  }
);

export const locationDeleted = createAsyncThunk(
  'locations/locationDeleted',
  ({id}, { getState, rejectWithValue }) => {
    const locations = JSON.parse(JSON.stringify(getState().locations.location));
    const i = findIndexById(locations,id);
    if(i === -1){
      return rejectWithValue("locationDeleted: the target location not found");
    }
    if(locations[i].children.length > 0){
      return rejectWithValue("locationDeleted: the target location has children -- cannot be deleted");
    }
    const parentI = findIndexById(locations, locations[i].parentId);
    // Remove the target location from locations
    locations.splice(i,1);
    // Remove id of the target location from the list of children of its parent location 
    if(parentI !== -1){
      const childI = locations[parentI].children.indexOf(id);
      locations[parentI].children.splice(childI,1);
    }
    return {newLocations:locations};
  }
);

export const locationUpdated = createAsyncThunk(
  'locations/locationUpdated',
  ({id: locationId, projectId, parentId, name, info, type,}, { getState, rejectWithValue }) => {
    // get a copy of locations
    const newLocations = JSON.parse(JSON.stringify(getState().locations.location));
    const i = findIndexById(newLocations, locationId);
    const targetLocation = newLocations[i];
    const oldParentId = targetLocation.parentId;
    const oldParentLocationInd = findIndexById(newLocations, oldParentId);
    let path = targetLocation.path;
    // If the location is moved, update some locations' children and paths if needed.
    if(parentId != null && parentId !== oldParentId) {
      const newParentLocationInd = findIndexById(newLocations, parentId);
      // Update path of the target location.
      path = newParentLocationInd === -1
        ? [locationId]
        : newLocations[newParentLocationInd].path.concat([locationId]);
      // If the OLD parent is not the top, remove the target location from its list of children.
      if(oldParentLocationInd !== -1) {
        const ind = newLocations[oldParentLocationInd].children.indexOf(locationId);
        newLocations[oldParentLocationInd].children.splice(ind, 1);
      }
      // If the NEW parent is not the top, add the target location to its list of children.
      if(newParentLocationInd !== -1) {
        newLocations[newParentLocationInd].children.push(locationId);
      }
      // Update recursively the children of the target location.
      updateChildLocationsPaths(targetLocation.children,path,newLocations);
    }

    newLocations[i] = {
      ...targetLocation,
      id: locationId,
      projectId,
      parentId: parentId < 1 ? null : parentId,
      // update rest of the data only if new is given
      name: name ?? targetLocation.name,
      info: info ?? targetLocation.info,
      type: type ?? targetLocation.type,
      path: path ?? targetLocation.path,
    }
    newLocations.sort(locationSortFunc);

    return {newLocations};
  }
);

export const expandAlarmLocations = createAsyncThunk(
  'locations/expandAlarmLocations',
  ({alarms}, { getState, rejectWithValue }) => {
    if(alarms == null || alarms.length === 0){
      rejectWithValue("expandAlarmLocations: alarms undefined")
    }
    // get a copy of the locations
    const newLocations = JSON.parse(JSON.stringify(getState().locations.location));
    // Recursive function that starts from a given location and goes to the top
    // expanding rows along the way.
    const openSupLocations = (id) => {
      if(id == null){
        return;
      }
      const currentLocation = findById(newLocations,id);
      currentLocation.showChildren = true;
      openSupLocations(currentLocation.parentId)
    }
    // For each alarm, expand location rows to the top.
    for (let alarm of alarms) {
      const locationId = newLocations.find( l => l.devices.includes(alarm.deviceId))?.id;
      if(locationId != null){
        openSupLocations(locationId);
      }
    }
    return {newLocations}
  }
);

export const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    locationsInit: (state, action) => {
      state.project = action.payload.projects;
      state.location = action.payload.locations.map(l => {
        const children = action.payload.locations.filter(loc => l.id === loc.parentId).map(loc => loc.id);
        const devices = l?.devices == null ? [] : l.devices.split(",").map(item => parseInt(item));
        const path = getPath(l.id, action.payload.locations);
        return {...l, children, devices, showChildren: false, path}
      })
        .sort(locationSortFunc) ?? [];
      const locationType = action.payload?.locationTypes?.toSorted(locationTypeSortFunc).map(t => t.name);
      state.locationType = locationType ?? [];
      state.dataIsLoaded = true;
    },
    locationAdded: (state, action) => {
      const {id, projectId, name, parentId, info, type, showParentChildren} = action.payload;
      if (projectId != null && name != null) {
        const parentLocationIndex = findIndexById(state.location, parentId);
        if (parentLocationIndex !== -1) {
          state.location[parentLocationIndex].children.push(id);
          state.location[parentLocationIndex].showChildren = showParentChildren ?? true;
        }
        const path = parentLocationIndex === -1
          ? [id]
          : state.location[parentLocationIndex].path.concat([id]);
        const newLocation = {
          id,
          projectId,
          parentId: parentId < 1 ? null : parentId,
          name,
          info,
          type: type ?? "other",
          children: [],
          devices: [],
          path,
          showChildren: false,
        }
        state.location.push(newLocation);
        state.location.sort(locationSortFunc)
      }
    },
  },
  extraReducers(builder) {
      builder
        .addCase(deviceMoved.fulfilled, (state, action) => {
        return {
          ...state,
          location: action.payload.newLocations,
          modifiedLocations: [...state.modifiedLocations, ...action.payload.newModifiedLocations]
        }
        })
        .addCase(locationDeleted.fulfilled, (state, action) => {
          state.location = action.payload.newLocations
        })
        .addCase(locationDeleted.rejected, (state, action) => {
          state.error = action.payload
        })
        .addCase(locationUpdated.fulfilled, (state,action) => {
          state.location = action.payload.newLocations
        })
        .addCase(locationUpdated.rejected, (state,action) => {
          state.error = action.payload
        })
        .addCase(expandAlarmLocations.fulfilled, (state,action) => {
          state.location = action.payload.newLocations
        })
  }
});

export const selectAllLocations = state => state.locations.location;

export const selectAllLocationTypes = state => state.locations.locationType;

export const selectLocationById = id => state => {
  if(id == null){
    return state.locations.location.filter( l => l.parentId === null ).map( l => l.id );
  }
  return state.locations.location.find( l => l.id === id);
}

export const selectCurrentProjectLocations = createSelector(
  [selectAllLocations, selectCurrentProjectId],
  (locations, currentProjectId) => locations.filter( l => (l.projectId === currentProjectId))
);

export const selectCurrentProjectLocationById = createSelector(
  [selectCurrentProjectLocations, (_,locationId) => locationId],
  (locations, locationId) => {
    if(locationId == null){
      return locations.filter( l => l.parentId === null ).map( l => l.id );
    }
    return locations.find( l => l.id === locationId);
  }
);

export const selectLocationByDeviceId = deviceId => state => {
  return state.locations.location.find( l => l.devices.includes(deviceId));
}

export const selectAllCurrentProjectBoundDevices = createSelector(
  (...args) => selectCurrentProjectLocations(...args), 
  locations => locations.reduce( (a, l) => a.concat(l.devices), [])
);

export const selectAllBoundDevices = state => state.locations.location.reduce( (a, l) => a.concat(l.devices), []);

export const selectLocationPrettyPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    const path = findById(locations, locationId)?.path;
    if(path == null){
      return "";
    }
    return path.slice(0,-1).map( id => findById(locations, id).name).join(' » ');
  }
);

export const selectLocationRawPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    return findById(locations, locationId)?.path;
  }
);

export const selectLocationFullPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    const path = findById(locations, locationId)?.path;
    if(path == null){
      return "";
    }
    return path.map( id => findById(locations, id).name).join(' » ');
  }
);

export const { 
  locationsInit,
  locationAdded,
} = locationsSlice.actions;
export default locationsSlice.reducer;