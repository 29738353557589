import { createElement } from "react";
import { withTranslation } from 'react-i18next';

import { AlarmLimitRelativeSlider } from "./AlarmLimitRelativeSlider.js";
import { AlarmTypeSetters } from "./AlarmTypeSetters.js";
import { Checkboxes } from "./Checkboxes.js";
import { HourCycleButtons } from "./HourCycleButtons.js";
import { InfoBox } from "./InfoBox.js";
import { NumberInput } from "./NumberInput.js"; 
import { RadioButtons } from "./RadioButtons.js";
import { RangeSlider } from "./RangeSlider.js";
import { Select } from "./Select.js";
import { Switch } from "./Switch.js";
import { ToggleButtons } from "./ToggleButtons.js";
import { UnitSystemButtons } from "./UnitSystemButtons.js";

const Components = {
  alarmTypeSetters: AlarmTypeSetters,
  slider: AlarmLimitRelativeSlider,
  checkboxes: Checkboxes,
  hourCycleButtons: HourCycleButtons,
  infoBox: InfoBox,
  numberInput: NumberInput,
  radioButtons: RadioButtons,
  rangeSlider: RangeSlider,
  select: Select,
  switch: Switch,
  toggleButtons: ToggleButtons,
  unitSystemButtons: UnitSystemButtons,
};

// Selects a component from the look-up table above and renders it if it is found.
// PARAMETER: block is an object:
// {
//   id         // componentId refers to the id in SQL DB: UIDB.UIdeviceDesc.id
//   _uid       // unique id for DOM
//   component  // component type
//   hide       // if true won't render the component
//   disabled   // disables selection etc. in component
// }
const PureSettingsComponent = ( block ) => {
  if(block.hide) {
    return createElement(
      () => <>&nbsp;</>,
      { key: block._uid }
    );
  };
  if(Components[block.component] == null) {
    return createElement(
      () => <div>The component {block.component} is not defined.</div>,
      { key: block._uid }
    );
  }
  return createElement(Components[block.component], {
    key: block._uid,
    block
  });
};
const SettingsComponent = withTranslation()(PureSettingsComponent);
export default SettingsComponent;