import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from "react-query";


import { checkMode } from '../theme.js';
import logger from '../utility/logger.mjs';
import { 
  selectDeviceById,
} from '../data/devicesSlice.js';
import { 
  selectCurrentDeviceId, 
  currentDeviceSet, 
  selectLoginType,
  timeRangeSet,
  selectLanguage,
  currentPageSet,
  brandSet,
  themeModeSet,
  selectThemeMode,
} from '../data/uiSlice.js';
import MapDialog from '../components/MapDialog.js';
import DeviceDetails from '../components/Devices/DeviceDetails.js';
import { 
  fetchOnomondoData, 
//  fetchActivationDates 
} from "../server/serverOperation.js";
import { ActivateDevicesDialog } from '../components/Devices/ActivateDevicesDialog.js';
import DeviceTable from '../components/Devices/DeviceTable.js'

import {
  Box,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  ListItemButton, 
} from '@mui/material/';
// import {
//   LocationOff,
//   LocationOn,
// } from '@mui/icons-material/';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const PureSubPageDeviceList = ({t}) => {
  useDispatch()(currentPageSet("DEVICE-LIST"));
  const loginType = useSelector(selectLoginType);
  // const deviceListData = useSelector(selectDeviceListData);
  // const onmondoUUIDs = deviceListData.filter( dev => dev.onomondoOK ).map( dev => dev.uuid );
  // const currentId = useSelector(selectCurrentDeviceId);
  // //const UUIDsWithoutActivationDate = deviceListData.filter( dev => dev.activationDate === undefined ).map( dev => dev.uuid ); 

  // const { data:onomondoData, refetch:refetchOnomondoData, status:onomondoDataStatus, isSuccess:onomondoDataIsSuccess } = useQuery(
  //   ["onomondoData"],
  //   () => {
  //     return fetchOnomondoData(onmondoUUIDs);
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  // const deviceTableRows = deviceListData.map( dev => {
  //   const uuid = dev.uuid;
  //   // const i = activationDates?.findIndex( d => d.uuid === uuid)
  //   // const activationDate = activationDates?.[i]?.activationDate.slice(0,10);
  //   // const lastSeen = activationDates?.[i]?.lastSeen;
  //   const activationDate = dev.activationTimes;
  //   const devData = { 
  //     ...dev, 
  //     ...onomondoData?.[uuid], 
  //   }
  //   return <DeviceTableRow dev={devData} currentId={currentId} selectSubPage={selectSubPage} onomondoKnown={onomondoDataIsSuccess} />   
  // })

  
  if(loginType !== "telegram"){
    return <>Log in with Telegram in order to see the devices linked to your phone number.</>;
  }

  return (
    <>
      <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t('devices-in-use')}</Typography>
      <Box sx={{height: "400px"}} >
        <DeviceTable />
      </Box>
      <Stack 
        direction="row"
        sx={{
          mt: 4,
          mb: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        >
        <Typography variant="h2" >{t('devices-not-in-use')}</Typography>
        <ActivateDevicesDialog />
      </Stack>
      <Box sx={{height: "400px"}}>
        <DeviceTable showDevicesNotInUse />
      </Box>
    </>
  )
}
const SubPageDeviceList = withTranslation()(PureSubPageDeviceList);

export default SubPageDeviceList;