import { withTranslation } from 'react-i18next';

import {devicesStatuses} from "../Devices/DeviceStatus";

import { 
  Tooltip 
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const iconComponent = {
  [devicesStatuses["ONLINE"]]: CheckIcon,
  [devicesStatuses["OFFLINE"]]: WarningRoundedIcon,
  [devicesStatuses["NOT_IN_USE_DEVICE_STATUS"]]: PersonOffIcon,
  [devicesStatuses["UNKNOWN"]]: QuestionMarkIcon, //default"
}

const iconColor = {
  [devicesStatuses["ONLINE"]]: "success",
  [devicesStatuses["OFFLINE"]]: "error",
  [devicesStatuses["NOT_IN_USE_DEVICE_STATUS"]]: "error",
  [devicesStatuses["UNKNOWN"]]: "warning" //default"
}

const TooltipWrapper = withTranslation()(({t, children, tooltipTitle, disabled, status}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={tooltipTitle ?? t(status?.toLowerCase() ?? "unknown")}>{children}</Tooltip>;
})

export default ({status, tooltipTitle, tooltipDisabled}) => {
  const Icon = iconComponent?.[status] || iconComponent.UNKNOWN;
  const color = iconColor?.[status] || iconColor.UNKNOWN;
  
  return (
    <TooltipWrapper tooltipTitle={tooltipTitle} disabled={tooltipDisabled} status={status} >
      <Icon color={color} fontSize="small" />
    </TooltipWrapper>
  );
}