/* eslint-disable no-dupe-keys */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      false: false, // shows nothing
      "account-settings": "Account settings",
      "logged-with-pin-code": "Logged with pin code",
      "dark-mode": "Dark mode on",
      "light-mode": "Dark mode off",
      "header-help-link": "HELP",
      "header-help-link-tooltip": "Open a help page",
      "select-language-tooltip": "Change language",
      "authentication-failed": "Authentication error.",
      "user-not-found": "The phone number is not registered with the Telegram bot.",
      "page-not-found-title": "Page not found!",
      "page-not-found-text": "Please, check your link.",
      "error-alert-title": "Error!",
      "warning-alert-title": "Warning!",
      "info-alert-title": "Info!",
      "success-alert-title": "Success!",
      "token-expired": "Link has expired. Please request new status message and try again.",
      "token-invalid": "Invalid link. Please request new status message and try again.",
      "invalid-url-code": "Invalide QR code. Please try to read the QR code again.",
      "setup-dialog-title": "Device setup",
      "setup-dialog-info-1": "Activate the device by adding a phone number and PIN code for superadmin.",
      "setup-dialog-2-alertinfo": "The phone number and the PIN code added for superadmin.",
      "setup-dialog-info-2": "Please confirm the phone number and activate Main-IoT alarm service in Telegram application.",
      "setup-dialog-info-2-more": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access. Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "setup-dialog-pin-text": "Create a six-digit PIN code. Keep the code in safe place!",
      "activate": "Activate",
      "activate-devices": "Activate devices",
      "activate-device": "Activate device",
      "activate-devices-dialog-button": "Activate devices",
      "activate-devices-dialog-info-1": "Activate selected devices. Give the superuser's phone number and create a PIN code for them.",
      "activate-devices-dialog-2-info-1": "Selected devices:",  
      "activate-devices-dialog-2-info-2": "The devices will be activated as and given super user's phone number will be associated to them.",
      "activated-devices": "Activated devices",
      "inactivated-devices": "Inactivive devices",
      "device-not-activated-warning": "The device is not activated.",
      "pin-error": "PIN code is too short.",
      "setup-number-error": "Enter a phone number.",
      "activate-bot": "Activate Main-IoT Bot",
      "next": "Next",
      "back": "Back",
      "open": "Open",
      "have-read": "I have read the document.",
      "agree": "Agree",
      "pin-change-info": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access.",
      "pin-change-info-more": "Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "no-privileges-to-change-pin-text": "Login as superadmin or with the PIN code to change the code.",
      "user-type": "user type",
      "your-privileges": "Your privileges",
      "user": "user",
      "guest": "guest",
      "admin": "admin",
      "superadmin": "superadmin",
      "no-privileges": "no privileges",
      "log-out": "Log out",
      "login": "Log in",
      "enter-pin": "Enter device PIN to log in.",
      "pin-code": "PIN code",
      "pin-incorrect": "Incorrect PIN code.",
      "pin-locked": "Too many failed login attempts. Pin code is locked for a short while.",
      "change-pin": "save PIN",
      "enter-new-pin": "new PIN code",
      "create-new-pin": "create a PIN code",
      "pin-is-saved": "New PIN code saved!",
      "generate-report-pdf": "generate report (PDF)",
      "download-report-pdf": "download report (PDF)",
      "download-report-csv": "download report (CSV)",
      "report": "Report",
      "data-report": "Data report",
      "select-range": "Select range",
      "range-start": "Range start",
      "range-end": "Range end",
      "range-start-end-error": "Range must start before it ends.",
      "range-befor-activation-warning": "Range cannot extend before the activation of the device.",
      "get-data": "Get data",
      "device-not-found": "Device not found!",
      "device": "Device",
      "devices": "Devices",
      "no-devices": "No devices",
      "devices-in-use": "Devices in use",
      "devices-not-in-use": "Devices not in use",
      "map": "Map",
      "multiguard": "MultiGuard",
      "device-id": "Device ID",
      "search": "Search",
      "name": "Name",
      "id": "id",
      "device-details": "Device details",
      "device-name": "Device name",
      "device-type": "Device type",
      "type": "Type",
      "info": "Info",
      "uuid": "UUID",
      "status": "Status",
      "online": "ONLINE",
      "offline": "OFFLINE",
      "unknown": "Unknown",
      "not_in_use_device_status": "The devices doesn't have an activated superuser",
      "time-zone": "Time zone",
      "last-seen": "Last seen",
      "activated": "Activated",
      "device-activated": "Device activated",
      "reference-measured": "Reference value measured",
      "time-left": "Time left",
      "not-found": "not found",
      "device-data": "Device data",
      "device-settings": "Device settings",
      "show-data": "Show data",
      "no-data": "NO DATA",
      "phonenumber": "Phone number",
      "phonenumbers": "Phone numbers",
      "numbers": "numbers",
      "call-numbers": "alarm call numbers",
      "new-number": "New phone number",
      "warning": "Warning!",
      "device-moved-info": "Device '{{deviceName}}' moved.",
      "device-name-error": "Device must have a name",
      "device-name-updated-info": "Device name '{{deviceName}}' updated.",
      "device-name-not-updated-error": "SERVER ERROR: Device name '{{deviceName}}' not updated.",
      "device-utc-offset-updated-info": "Device UTC zone offset updated.",
      "utc-offset-not-updated-error": "SERVER ERROR: Device UTC zone offset not updated.",
      "devices-activated-info": "Device(s) {{deviceNames}} activated.",
      "activate-devices-error": "SERVER ERROR: Devices not activated.",
      "error-no-superadmin-selected": "One confirmed phone number must be selected as super admin or the device will be reset.",
      "warning-deleting-superadmin-title": "You are resetting the device!",
      "no-superadmin-confirm-warning": "No phone number connected to the Telegram bot is selected as super admin. If you confirm now, the device will be reset. In order to use it again it must be initialized with the QR code.",
      "warning-losing-admin-privileges-title": "Downgrading own privileges",
      "warning-losing-admin-privileges": "Notice that you have downgraded your own user privileges. You cannot undo the change afterwards.",
      "warning-deleting-oneself-title": "Deleting own phone number",
      "warning-deleting-oneself": "Notice that you are deleting your own number! You will be logged out and cannot log in anymore.",
      "warning-at-most-one-superadmin": "The device may have only one superadmin.",
      "phonenumbers-info-inDialog-3": ". After confirmation only the owner can unlink the number from the device, or it must be done by logging in using the PIN code.",
      "phonenumbers-info": `Alarm messages will be send to these phone numbers. Phone numbers marked with a green telephone will get alarm calls. If the background of a number is red, the number is not in use. In that case, please ask the owner of the number to confirm the number with `,
      "with-telegram-bot": "Main-IoT Telegram bot",
      "telegram-bot": "The Telegram bot",
      "edit-numbers": "Edit numbers",
      "insert-phonenumber": "A new phone number",
      "add": "add",
      "edit": "Edit",
      "clear": "clear",
      "close": "close",
      "no-phone-numbers-set": "No phone numbers set",
      "undo": "Undo",
      "save": "Save",
      "number-check-ok": "Numbers are checked", 
      "check-numbers": "Check numbers",
      "numbers-check-header": "Check numbers",
      "project": "Project",
      "location": "Location",
      "free-devices": "Free devices",
      "location-name": "Name",
      "location-name-placeholder": "The name of the location",
      "location-info": "Info",
      "location-info-placeholder": "Info about the location",
      "position": "Position",
      "location-type": "Type",
      "no-location": "No location",
      "add-location": "Add a location",
      "move-location": "Move the location",
      "location-move-info-text": "Move location '{{locationName}}' under:",  // in LocationMoveDialog
      "location-move-info": "Location '{{locationName}}' moved.",
      "location-move-error": "Error: Location '{{locationName}}' not moved.",
      "add-sublocation": "Add a sublocation",
      "edit-location": "Edit the location",
      "delete-location": "Delete the location",
      "location-delete-confirmation": "Delete the location?",
      "location-added-info": "Location '{{locationName}}' added",
      "location-deleted-info": "Location '{{locationName}}' deleted",
      "location-updated-info": "Location '{{locationName}}' updated",
      "location-add-error": "Server error: Location not added! Try to reload the page and try again.",
      "location-edit-error": "Server error: Location not updated! Try to reload the page and try again.",
      "location-delete-error": "Server error: Location not deleted! Try to reload the page and try again.",
      "location-has-children-error": "Location with sublocations cannot be deleted.",
      "show-location": "Show location",
      "expand": "Expand",
      "expand-all": "Expand all",
      "collapse": "Collapse",
      "collapse-all": "Collapse all",
      "sensitivity-buttons": "Sensor sensitivity",
      "button-off": "Off",
      "button-sensitive": "Sensitive",
      "button-insensitive": "Insensitive",
      "button-sensitive-info-pressure": "Inline.",
      "button-insensitive-info-pressure": "Tank pressure.",
      "send-settings": "Send settings",
      "settings": "Settings",
      "sensor": "Sensor",
      "unit-system": "Unit system",
      "hour-cycle": "Clock",
      "idle-time-input-title": "Message idle time",
      "24h": "24-hour",
      "12h": "12-hour",
      "settings-tab": "Settings",
      "advanced-tab": "Advanced",
      "updating-settings-LTE-M-info-text": "Settings will be updated to device within 10 minutes.",
      "updating-settings-Sigfox-info-text": "Settings will be updated to device within 24 hours.",
      "data-tab": "Data",
      "store-tab": "Store",
      "devices-tab": "Devices",
      "device-list-tab": "List",
      "locations-tab": "Locations",
      "advanced-settings": "Advanced",
      "three-hours": "3 hours",
      "one-day": "24h",
      "one-week": "a week",
      "one-month": "a month",
      "six-months": "6 months",
      "one-year": "a year",
      "custom-timerange": "custom",
      "from-activation-time": "From activation",
      "from-reference-time": "From reference",
      "reference-time-button": "ref",
      "three-hours-button": "3h",
      "one-day-button": "24h",
      "one-week-button": "1w",
      "one-month-button": "1m",
      "six-months-button": "6m",
      "one-year-button": "1y",
      "activation-time-button": "activ",
      "custom-timerange-button": "set",
      "time-range": "Time range",
      "time-range-start": "Start",
      "time-range-end": "End",
      "latest-reboot": "Reference",
      "reference-value": "Reference value",
      "reference-value-abbreviation": "Ref. value",
      "latest-value": "Latest value",
      "limit-type": "Alarm limit type",
      "alarm-limits": "Alarm limits",
      "alarm-limit-type-absolute": "Absolute",
      "alarm-limit-type-absolute-info": "User adjustable, fixed alarm limits.",
      "alarm-limit-type-reference-based": "Reference",
      "alarm-limit-type-reference-based-info": "The limits are relative to the reference value.",
      "alarm": "Alarm",
      "yes": "yes",
      "no": "no",
      "off": "off",
      "on": "on",
      "ok": "ok",
      "reset": "reset",
      "cancel": "cancel",
      "enable-alarms": "Enabled alarms",
      "alarm-types": "Alarm types",
      "psuMonitorAD-on": "AC power",
      "psuMonitorAD-off": "AC power disconnected",
      "temperature": "Temperature",
      "humidity": "Humidity",
      "luminance": "Luminance",
      "VOC": "VOC",
      "CO2": "CO₂",
      "PIR": "Movement",
      "SPL": "Sound pressure",
      "general": "General settings",
      "pressure": "Pressure",
      "batteryVoltage": "Battery voltage",
      "leakSensorADValue": "Leak sensor",
      "wet": "wet",
      "dry": "dry",
      "normal": "normal",
      "alarms": "Alarms",
      "time": "Time",
      "show": "Show",
      "show-alarms": "Show alarms",

      "general-abbreviation": "Gen",
      "psuMonitorAD-off-abbreviation": "Pow",
      "temperature-abbreviation": "Temp",
      "humidity-abbreviation": "Hum",
      "VOC-abbreviation": "VOC",
      "CO2-abbreviation": "CO₂",
      "PIR-abbreviation": "Move",
      "luminance-abbreviation": "LUX",
      "SPL-abbreviation": "Sound",
      "pressure-abbreviation": "Pres",
      "batteryVoltage-abbreviation": "Bat",
      "leakSensorADValue-abbreviation": "Leak",

      // location type names
      "top": "Top level",
      "city": "City",
      "address": "Address",
      "building": "Building",
      "staircase": "Staircase",
      "floor": "Floor",
      "apartment": "Apartment",
      "room": "Room",
      "other": "Other",

      // Setting and data labels
      "label-sAmao":                "max overshoot",
      "label-sAmad":                "max drop",
      "label-sAhlt":                "upper limit",
      "label-sAllt":                "lower limit",
      "label-temperatureHighLimit": "upper limit",
      "label-temperatureLowLimit":  "lower limit",
      "label-humidityHighLimit":    "upper limit",
      "label-humidityLowLimit":     "lower limit",
      "label-splHighLimit":         "upper limit",
      "label-splLowLimit":          "lower limit",
      "label-pirHighLimit":         "upper limit",
      "label-pirLowLimit":          "lower limit",
      "label-co2HighLimit":         "upper limit",
      "label-co2LowLimit":          "lower limit",
      "label-vocHighLimit":         "upper limit",
      "label-vocLowLimit":        "lower limit",
      "label-luxHighLimit":         "upper limit",
      "label-luxLowLimit":        "lower limit",
    }
  },
  fi: {
    translation: {
      false: false, // shows nothing
      "account-settings": "Tilin asetukset",
      "logged-with-pin-code": "Kirjauduttu pin-koodilla",
      "dark-mode": "Tumma tila päällä",
      "light-mode": "Tumma tila pois",
      "header-help-link": "TUKI",
      "header-help-link-tooltip": "Avaa opastussivu",
      "select-language-tooltip": "Vaihda kieli",
      "authentication-failed": "Käyttäjän autenktikointi ei onnistunut.",
      "user-not-found": "Puhelinnumeroa ei ole rekisteröity Telegram botin avulla.",
      "page-not-found-title": "Sivua ei löydy!",
      "page-not-found-text": "Tarkista, että linkkisi on oikea.",
      "error-alert-title": "Virhe!",
      "warning-alert-title": "Varoitus!",
      "info-alert-title": "Info!",
      "success-alert-title": "Onnistui!",
      "token-expired": "Linkki on vanhentunut. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "token-invalid": "Linkki ei toimi. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "invalid-url-code": "Virheellinen QR-koodi. Kokeile lukea koodi uudelleen.",
      "setup-dialog-title": "Laitteen käyttöönotto",
      "setup-dialog-info-1": "Aktivoi laite lisäämällä pääkäyttäjän puhelinnumero ja PIN-koodi.",
      "setup-dialog-2-alertinfo": "Puhelinnumero ja PIN-koodi lisätty superadmille.",
      "setup-dialog-info-2": "Varmenna numero ja aktivoi Main-IoT-hälytyspalvelu Telegram-sovelluksessa.",
      "setup-dialog-info-2-more": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta. Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numeroa ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä, PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.", 
      "setup-dialog-pin-text": "Luo oma kuusinumeroinen PIN-koodisi. Pidä PIN-koodi varmassa tallessa!",
      "activate": "Aktivoi",
      "activate-devices": "Aktivoi laitteita",
      "activate-device": "Aktivoi laite",
      "activate-devices-dialog-button": "Aktivoi laitteita",
      "activate-devices-dialog-info-1": "Valitse listalta aktivoitavat laitteet. Anna laitteille pääkäyttäjän puhelinnumero ja PIN-koodi.",
      "activate-devices-dialog-2-info-1": "Aktivoitavat laitteet:",
      "activate-devices-dialog-2-info-2": "Laitteet aktivoidaan ja niihin liitetään antamasi pääkäyttäjän numero.",
      "activated-devices": "Aktivoidut laitteet",
      "inactivated-devices": "Aktivoimattomat laitteet",
      "device-not-activated-warning": "Laitetta ei ole aktivoitu.",
      "pin-error": "PIN-koodi on liian lyhyt.",
      "setup-number-error": "Anna puhelinnumero.",
      "activate-bot": "Aktivoi Main-IoT-botti",
      "next": "Jatka",
      "back": "Takaisin",
      "open": "Avaa",
      "have-read": "Olen lukenut dokumentin.",
      "agree": "Hyväksyn",
      "pin-change-info": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta.",
      "pin-change-info-more": "Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numero ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.",
      "no-privileges-to-change-pin-text": "Kirjaudu pääkäyttäjänä tai PIN-koodilla vaihtaaksesi PIN-koodin.",
      "user-type": "käyttäjätyyppi",
      "your-privileges": "Käyttäjäoikeutesi",
      "user": "käyttäjä",
      "guest": "vieras",
      "admin": "ylläpitäjä",
      "superadmin": "pääkäyttäjä",
      "no-privileges": "ei käyttöoikeutta",
      "log-out": "Kirjaudu ulos",
      "login": "Kirjaudu",
      "enter-pin": "Syötä laitteen PIN-koodi kirjautuaksesi sisään.",
      "pin-code": "PIN-koodi",
      "pin-incorrect": "Virheellinen PIN-koodi.",
      "pin-locked": "Liian monta epäonnistunutta kirjautumisyritystä. PIN-koodi on lukittu hetkeksi.",
      "change-pin": "tallenna PIN",
      "enter-new-pin": "uusi PIN-koodi",
      "create-new-pin": "keksi PIN-koodi",
      "pin-is-saved": "Uusi PIN-koodi tallennettu laitteelle!",
      "generate-report-pdf": "luo raportti (PDF)",
      "download-report-pdf": "lataa raportti (PDF)",
      "download-report-csv": "Lataa raportti (CSV)",
      "report": "Raportti",
      "data-report": "Dataraportti",
      "select-range": "Valitse tarkasteluväli",
      "range-start": "Välin alku",
      "range-end": "Välin loppu",
      "range-start-end-error": "Aikavälin täytyy alkaa ennen loppua.",
      "range-befor-activation-warning": "Aikavälin oltava laitteen aktivoinnin jälkeisellä ajalla.",
      "get-data": "Hae data",
      "device-not-found": "Laitetta ei löydy!",
      "device": "Laite",
      "devices": "Laitteet",
      "no-devices": "Ei laitteita",
      "devices-in-use": "Käytössä olevat laitteet",
      "devices-not-in-use": "Ei käytössä olevat laitteet",
      "map": "Kartta",
      "multiguard": "MultiGuard",
      "device-id": "Laitteen ID",
      "search": "Etsi",
      "name": "Nimi",
      "device-name": "Laitteen nimi",
      "device-type": "Laitteen tyyppi",
      "type": "Tyyppi",
      "info": "Info",
      "id": "id",
      "uuid": "UUID",
      "status": "Status",
      "online": "ONLINE",
      "offline": "OFFLINE",
      "unknown": "Ei tiedossa",
      "not_in_use_device_status": "Laitteelle ei ole aktivoitua superadminia.",
      "time-zone": "Aikavyöhyke",
      "last-seen": "Viimeksi nähty",
      "activated": "Aktivoitu",
      "device-activated": "Laite aktivoitu",
      "reference-measured": "Referenssiarvo mitattu",
      "time-left": "Aikaa jäljellä",
      "not-found": "ei löydy",
      "device-data": "Laitteen data",
      "device-settings": "Laitteen asetukset",
      "no-data": "EI DATAA",
      "phonenumber": "Puhelinnumero",
      "numbers": "numerot",
      "call-numbers": "hälytyssoittonumerot",
      "phonenumbers": "Puhelinnumerot",
      "new-number": "Uusi puhelinnumero",
      "phonenumbers-info-inDialog-1": "Lisää pääkäyttäjän numero laitteelle. Jos alla näkyvä numero on punainen, käyttäjä ei ole varmantanut sitä. Varmennus on tehtävä ",
      "warning": "Varoitus!",
      "device-moved-info": 'Laite "{{deviceName}}" siirretty',
      "device-name-error": "Anna laitteelle nimi",
      "device-name-updated-info": 'Laitteen nimi "{{deviceName}}" päivitetty',
      "device-name-not-updated-error": "SERVERIVIRHE: Laitteen nimeä ei päivitetty!",
      "device-utc-offset-updated-info": "Laitteen aikavyöhyke päivitetty",
      "devices-activated-info": "Laite/laitteet {{deviceNames}} aktivoitu.",
      "utc-offset-not-updated-error": "SERVERIVIRHE: Laitteen aikavyöhykettä ei päivitetty!",
      "activate-devices-error": "SERVERIVIRHE: Laitteita ei aktivoitu.",
      "error-no-superadmin-selected": "Yksi käyttäjä täytyy olla valittuna superadminiksi.",
      "warning-deleting-superadmin-title": "Olet nollaamassa laitteen!",
      "no-superadmin-confirm-warning": "Superadminia ei ole valittu. Jos hyväksyt valinnat, laite resetoituu eikä sitä voi enää käyttää. Se on otettava uudelleen käyttöön QR-koodin avulla.",
      "warning-losing-admin-privileges-title": "Omien käyttöoikeuksien alentaminen",
      "warning-losing-admin-privileges": "Olet alentamassa omia käyttöoikeuksiasi. Huomaa, ettet voi itse palauttaa niitä myöhemmin.",
      "warning-deleting-oneself-title": "Oman puhelinnumeron poistaminen",
      "warning-deleting-oneself": "Olet poistamassa oman numerosi. Jos tallennat valinnat, sinut kirjataan ulos etkä voi enää kirjautua uudelleen sisään.",
      "warning-at-most-one-superadmin": "Laitteella voi olla vain yksi superadmin.",
      "with-telegram-bot": "Main-IoT Telegram -botilla",
      "phonenumbers-info-inDialog-3": ", ja varmentamisen jälkeen vain pääkäyttäjä voi poistaa oman numeronsa.",
      "phonenumbers-info": "Näihin numeroihin lähetetään hälytysviesti. Jos numeron luuri-ikoni on merkkitty vihreäksi, siihen soitetaan hälytyspuheluita. Numero, jonka taustaväri on punainen, eivät ole käytössä. Pyydä tällöin numeron haltijaa hyväksymään sen käyttö ",
      "telegram-bot": "Telegram-botti",
      "edit-numbers": "Muokkaa numeroita",
      "insert-phonenumber": "Uusi puhelinnnumero",
      "no-phone-numbers-set": "Puhelinnumeroita ei ole asetettu",
      "undo": "Peruuta",
      "send-settings": "Lähetä asetukset",
      "save": "Tallenna",
      "add": "lisää",
      "edit": "Muokkaa",
      "number-check-ok": "Numerot tarkistettu", 
      "check-numbers": "Tarkista numerot",
      "numbers-check-header": "Numeroiden tarkistus", 
      "clear": "palauta",
      "close": "sulje",
      "project": "Projekti",
      "location": "Kohde",
      "free-devices": "Vapaat laitteet",
      "location-name": "Nimi",
      "location-name-placeholder": "Kohteen nimi",
      "location-info": "Info",
      "location-info-placeholder": "Lisätietoja kohteesta",
      "position": "Sijainti",
      "location-type": "Tyyppi",
      "no-location": "Ei sijaintia",
      "add-location": "Lisää kohde",
      "add-sublocation": "Lisää alakohde",
      "move-location": "Siirrä kohde",
      "location-move-info-text": 'Siirrä kohde "{{locationName}}" kohteen alle:',  // in LocationMoveDialog
      "location-move-info": 'Kohde "{{locationName}}" siirretty.',
      "location-move-error": 'Virhe: Kohdetta "{{locationName}}" ei siirretty.',
      "edit-location": "Muokkaa kohdetta",
      "delete-location": "Poista kohde",
      "location-delete-confirmation": "Poistetaanko kohde?",
      "location-added-info": 'Kohde "{{locationName}}" lisätty',
      "location-deleted-info": 'Kohde "{{locationName}}" poistettu',
      "location-updated-info": 'Kohde "{{locationName}}" päivitetty',
      "location-add-error": "Serverivirhe: Kohdetta ei lisätty. Lataa sivu uudelleen ja yritä uudelleen.",
      "location-edit-error": "Serverivirhe: Kohdetta ei muutettu. Lataa sivu uudelleen ja yritä uudelleen.",
      "location-delete-error": "Serverivirhe: Kohdetta ei poistettu. Lataa sivu uudelleen ja yritä uudelleen.",
      "location-has-children-error": "Kohdetta, jolla on alakohteita, ei voi poistaa.",
      "show-location": "Näytä kohde",
      "expand": "Laajenna",
      "expand-all": "Laajenna kaikki",
      "collapse": "Supista",
      "collapse-all": "Supista kaikki",
      "sensitivity-buttons": "Anturiherkkyys",
      "button-off": "Pois päältä",
      "button-sensitive": "Herkkä",
      "button-insensitive": "Epäherkkä",
      "button-sensitive-info-pressure": "Kytketty linjaan.",
      "button-insensitive-info-pressure": "Kytketty suoraan kompressoriin.",
      "settings": "Asetukset",
      "device-details": "Laitteen tiedot",
      "unit-system": "Yksiköt",
      "hour-cycle": "Kello",
      "idle-time-input-title": "Viestiväli",
      "24h": "24-tuntinen",
      "12h": "12-tuntinen",
      "settings-tab": "Asetukset",
      "advanced-tab": "Edistynyt",
      "updating-settings-LTE-M-info-text": "Asetukset päivittyvät laitteelle 10 minuutin sisällä.",
      "updating-settings-Sigfox-info-text": "Asetukset päivittyvät laitteelle vuorokauden sisällä.",
      "data-tab": "Data",
      "store-tab": "Kauppa",
      "devices-tab": "Laitteesi",
      "device-list-tab": "Lista",
      "locations-tab": "Kohteesi",
      "advanced-settings": "Lisäasetukset",
      "three-hours": "3 tuntia",
      "one-day": "vuorokausi",
      "one-week": "viikko",
      "one-month": "kuukausi",
      "six-months": "6 kuukautta",
      "one-year": "vuosi",
      "custom-timerange": "mukautettu",
      "from-activation-time": "Aktivoinnista",
      "from-reference-time": "referenssimittauksesta",
      "reference-time-button": "ref",
      "three-hours-button": "3t",
      "one-day-button": "24t",
      "one-week-button": "7vrk",
      "one-month-button": "1kk",
      "six-months-button": "6kk",
      "one-year-button": "1v",
      "activation-time-button": "akt",
      "custom-timerange-button": "valitse",
      "time-range": "Aikaväli",
      "time-range-start": "Alku",
      "time-range-end": "Loppu",
      "latest-reboot": "Referenssi",
      "reference-value": "Referenssiarvo",
      "reference-value-abbreviation": "Ref.-arvo",
      "latest-value": "Viimeisin arvo",
      "limit-type": "Hälytysrajatyyppi",
      "alarm-limits": "Hälytysrajat",
      "alarm-limit-type-absolute": "Absoluuttinen",
      "alarm-limit-type-absolute-info": "Käyttäjän asettamat hälytysrajat.",
      "alarm-limit-type-reference-based": "Referenssi",
      "alarm-limit-type-reference-based-info": "Rajat lasketaan suhteellisesti referenssiarvosta.",
      "yes": "kyllä",
      "no": "ei",
      "alarm": "Hälytys",
      "off": "pois",
      "on": "päällä",
      "ok": "ok",
      "reset": "nollaa",
      "cancel": "peru",    
      "sensor": "Sensori",
      "general": "Yleisesasetukset",
      "enable-alarms": "Hälytykset päällä",
      "alarm-types": "Hälytystyypit",
      "psuMonitorAD-on": "Verkkovirta",
      "psuMonitorAD-off": "Verkkovirtaa ei kytketty",
      "temperature": "Lämpötila",
      "humidity": "Kosteus",
      "luminance": "Valoisuus",
      "VOC": "VOC",
      "CO2": "CO₂",
      "PIR": "Liike",
      "SPL": "Äänenpaine",
      "pressure": "Paine",
      "batteryVoltage": "Akku",
      "leakSensorADValue": "Vuotosensori",
      "wet": "märkä",
      "dry": "kuiva",
      "normal": "normaali",
      "alarms": "Hälytykset",
      "time": "Aika",
      "show": "Näytä",
      "show-alarms": "Näytä hälytykset",

      "general-abbreviation": "Yleis",
      "psuMonitorAD-off-abbreviation": "Virta",
      "temperature-abbreviation": "Lämp",
      "humidity-abbreviation": "Kost",
      "VOC-abbreviation": "VOC",
      "CO2-abbreviation": "CO₂",
      "PIR-abbreviation": "Liike",
      "luminance-abbreviation": "Valo",
      "SPL-abbreviation": "Ääni",
      "pressure-abbreviation": "Paine",
      "batteryVoltage-abbreviation": "Akku",
      "leakSensorADValue-abbreviation": "Vuoto",

      // location types
      "top": "Ylin taso",
      "city": "Kaupunki",
      "address": "Osoite",
      "building": "Rakennus",
      "staircase": "Rappu",
      "floor": "Kerros",
      "apartment": "Asunto",
      "room": "Huone",
      "other": "Muu",

      // Setting and data labels
      "label-sAmao":                "maksimiylitys",
      "label-sAmad":                "maksimialitus",
      "label-sAhlt":                "yläraja",
      "label-sAllt":                "alaraja",
      "label-temperatureHighLimit": "yläraja",
      "label-temperatureLowLimit":  "alaraja",
      "label-humidityHighLimit":    "yläraja",
      "label-humidityLowLimit":     "alaraja",
      "label-splHighLimit":         "yläraja",
      "label-splLowLimit":          "alaraja",
      "label-pirHighLimit":         "yläraja",
      "label-pirLowLimit":          "alaraja",
      "label-co2HighLimit":         "yläraja",
      "label-co2LowLimit":          "alaraja",
      "label-vocHighLimit":         "yläraja",
      "label-vocLowLimit":        "alaraja",
      "label-luxHighLimit":         "yläraja",
      "label-luxLowLimit":        "alaraja",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false,
  });

  export default i18n;