import { useSelector } from "react-redux";

import { selectLanguage } from "../../data/uiSlice";
import { translationKey } from "../../utility/userConfig.mjs";

import { fiFI } from '@mui/x-data-grid-pro/locales';
const translation = { fiFI };

export default () => {
  const language = useSelector(selectLanguage);
  const key = translationKey?.[language];
  if(key == null){
    return null;
  }
  return translation[key].components.MuiDataGrid.defaultProps.localeText;
}