import DeviceDetails from "./DeviceDetails";
import {RawDialog} from "../InfoDialog";
import {AlarmIcon} from "../Icons/AlarmIcon";

import {IconButton, Tooltip} from "@mui/material";
import {withTranslation} from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {useState} from "react";

const DeviceDetailsDialog = withTranslation()(({t, deviceId, openButtonType, hideActionButtons}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const openButton = () => {
    switch (openButtonType) {

      // possible other cases:
      // case "case":
      //   return ...

      default:  // "openIcon"
        return <Tooltip title={t("device-details")} ><InfoOutlinedIcon /></Tooltip>
    }
  }

  return (
    <>
      <IconButton onClick={() => setDialogOpen(true)} >
        {openButton()}
      </IconButton>
      <RawDialog
        dialogOpen={dialogOpen}
        data={{
          title: <>{t("device-details")}</>,
          hideOpenButton: true,
          hideDialogActionButtons: true,
          enableTitleCloseButton: true,
          okButtonText: "close",
          okButtonFunc: () => setDialogOpen(false),
          setDialogOpen,
        }}
      >
        <DeviceDetails
          deviceId={deviceId}
          hideDataButton={hideActionButtons}
          hideSettingsButton={hideActionButtons}
        />
      </RawDialog>
    </>
  )
})

export default DeviceDetailsDialog;
